import {Component, Input, OnInit} from '@angular/core';
import {InfoDto, InfoListDto} from "@server-models";
import {CommonModule, DatePipe} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {postItemsMyRead} from "@team/pages/infos/store/team-info.actions";
import {Store} from "@ngrx/store";
import {TeamInfoButtonsItemComponent} from "@team/pages/infos/components/buttons-item/team-info-buttons-item.component";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";

@Component({
  selector: 'app-team-info-card',
  templateUrl: './team-info-card.component.html',
  styleUrls: ['./team-info-card.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedSafePipe, TeamInfoButtonsItemComponent, SharedUserHtmlComponent, SharedAttachmentsComponent],
  providers: [
    DatePipe,
  ]

})
export class TeamInfoCardComponent implements OnInit {

  @Input() info: InfoListDto;

  constructor(private _datePipe: DatePipe, private _store: Store) {
    this.info = {};
  }

  ngOnInit() {
  }

  /**
   * @name formatCardSubtitle
   * @memberof TeamInfoCardComponent
   * @description
   * Function to display the card subtitle with a specific format
   * @param info
   * @return {string}
   */
  formatCardSubtitle(info: InfoDto): string {
    const preCard: string = info.sender?.name ? `${ info.sender.name }` : '';
    const postCard: string = info?.dateTime ? `, ${ this._datePipe.transform(info?.dateTime, "dd.MM hh:mm") }` : ``;
    return `${ preCard }${ postCard }`;
  }

  /**
   * @name read
   * @memberof TeamInfoCardComponent
   * @description
   * Dispatch the action read from the store
   * @param infoId
   */
  read(infoId: number | undefined) {
    this._store.dispatch((postItemsMyRead({ id: infoId as number })));
  }

}
