<ion-buttons slot="start">
  <ion-menu-button *ngIf="isShowMenu" (click)="openMenu()"></ion-menu-button>
  <ion-title>
    {{ title }}
    <ion-badge *ngIf="quantity">
      <ion-text>{{ quantity }}</ion-text>
    </ion-badge>
  </ion-title>
  <ng-content></ng-content>
</ion-buttons>
