<ion-item *ngIf="info.requestRead && !info.isRead; else actionRead">
  <ion-buttons>
    <ng-container *ngIf="!(checkLoadingButton(info.infoId) | async); else loading">
      <ion-button color="primary" [id]="info.infoId" (click)="read(info.infoId)" fill="outline">
        <ion-icon name="eye-outline"></ion-icon>&nbsp;{{ 'TEAM.INFOS.CARDS.BUTTONS.READ' | translate }}
      </ion-button>
    </ng-container>
  </ion-buttons>
</ion-item>

<ng-template #actionRead>
  <ion-item *ngIf="info.isRead">
    <ion-buttons>
      <ng-container>
        <ion-icon size="large"
                  name="checkbox-outline"></ion-icon>&nbsp;{{ 'TEAM.INFOS.CARDS.BUTTONS.READ_ALREADY' | translate }}
      </ng-container>
    </ion-buttons>
  </ion-item>
</ng-template>

<ng-template #loading>
  <ion-button disabled="disabled" fill="outline">
    <ion-icon name="eye-outline"></ion-icon>
    <ion-spinner></ion-spinner>
  </ion-button>
</ng-template>

