import {Injectable} from "@angular/core";
import {StereotypeApiService} from "@shared/components/issues/services/stereotype/stereotype-api.service";
import {Observable} from "rxjs";
import {OrgaResponse} from "@shared/interfaces/orga-response.interface";
import {StereotypeDto, StereotypeListDto} from "@server-models";
import {TeamHttpClientBaseApiService} from "@team/services/http-client-base-api/team-http-client-base-api.service";

@Injectable({
  providedIn: 'root',
})
export class TeamStereotypeApiService extends TeamHttpClientBaseApiService implements StereotypeApiService {
  /**
   * @name getStereotypeById
   * @description
   * make a request to stereotype by id
   * @memberof TeamStereotypeApiService
   * @param id
   * @returns {Observable<StereotypeDto>}
   */
  getStereotypeById(id: number): Observable<StereotypeDto> {
    return super.get<StereotypeDto>(`/tech/stereotype/${id}`, undefined);
  }

  /**
   * @name getStereotypeFilterByEntityType
   * @description
   * make a request to stereotype using entityType and cache-control
   * @memberof TeamStereotypeApiService
   * @param entityType
   * @param cacheControl
   * @returns {Observable<OrgaResponse<StereotypeListDto>>}
   */
  getStereotypeFilterByEntityType(entityType: string, cacheControl: string): Observable<OrgaResponse<StereotypeListDto>> {
    const options = {
      params: {
        entityType: entityType.toString(),
        Cols: 'CustomPropertySets',
        'cache-control': cacheControl
      }
    }
    return super.get<OrgaResponse<StereotypeListDto>>(`/tech/stereotype`, undefined, options);
  }
}
