import {Injectable} from "@angular/core";
import {ToastController} from "@ionic/angular";
import {from, Observable} from "rxjs";
import {ETeamToastPosition} from "@team/enums/team-toast-position.enum";
import {IToastOptions} from "@shared/services/interfaces/toast-options.interface";

@Injectable({
  providedIn: 'root',
})
export class ToastControllerService {
  constructor(
    private _toastController: ToastController
  ) {
  }

  /**
   * @name _presentToast
   * @memberof ToastControllerService
   * @description
   * Display a toast by params
   * @param message
   * @param position
   * @param duration
   * @param icon
   * @param color
   * @private
   */
  private async _presentToast(message: string, position: ETeamToastPosition, duration: number, icon: string,
                              color: string): Promise<void> {
    const toast = await this._toastController.create({
      message,
      duration,
      position,
      icon,
      color
    });

    await toast.present();
  }

  /**
   * @name observableToast
   * @memberof ToastControllerService
   * @description
   * return an observable of toast
   * @param opts
   */
  observableToast(
    opts: IToastOptions
  ): Observable<void> {
    const {
      message,
      position = ETeamToastPosition.Bottom,
      duration = 3000,
      icon = "",
      color = "dark"
    } = opts;
    return from(this._presentToast(message, position, duration, icon, color));
  }
}
