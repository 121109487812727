import {createAction, props} from "@ngrx/store";

export const getLogo = createAction(
  '[SideBar-Team] Logo Start'
);

export const getLogoSuccess = createAction(
  '[SideBar-Team] Logo Success',
  props<{file: Blob, imgLogo: string, name: string, id: number}>()
);

export const getLogoFail = createAction(
  '[SideBar-Team] Logo Fail',
  props<{ error: any }>()
);
