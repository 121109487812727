import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {TeamKnowledgeEffectsBase} from "@team/pages/knowledge/store/knowledge.effects";
import {mergeMap, Observable, throwError, withLatestFrom} from "rxjs";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {KnowledgeArticleListDto, KnowledgePathListDto, KnowledgePathStartDto} from "@server-models";
import {
  selectKnowledgePathPagination,
  selectKnowledgePathStart
} from "@team/pages/knowledge/store/knowledge.selectors";
import {catchError, map} from "rxjs/operators";
import {KnowledgeActions} from "@team/pages/knowledge/store/knowledge.actions-type";
import {IKnowledgePathStartIdRequest} from "../interfaces/knowledge-path-start-id-request.interface";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {
  IKnowledgePathRequestPagination
} from "@team/pages/knowledge/interfaces/knowledge-path-request-pagination.interface";
import {
  IAttachmentKnowledgeArticle
} from "@shared/components/attachments/interfaces/attachment-knowledge-article.interface";
import {selectAttachments} from "@shared/components/attachments/store/attachments.selectors";
import {SharedKnowledgeService} from "@shared/services/shared-knowledge.service";

@Injectable({
  providedIn: 'root'
})
export class TeamKnowledgeApiEffects extends TeamKnowledgeEffectsBase {

  constructor(
    actions$: Actions,
    store: Store,
    private _sharedKnowledgeService: SharedKnowledgeService
  ) {
    super(store, actions$);
  }

  /**
   * @name getPath
   * @description
   * Receive the action started on TeamKnowledgeEffectsBase and use the latest state from knowledge.pagination
   * to check if there is more pages available and call a function to request knowledge path data
   * @memberof TeamKnowledgeApiEffects
   * @param action$
   * @return {Observable<(IOrgaResponse<KnowledgePathListDto[]> | any)>}
   */
  getPath(action$: Observable<IKnowledgePathRequestPagination>): Observable<(IOrgaResponse<KnowledgePathListDto[]> | any)> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(selectKnowledgePathPagination))),
      mergeMap(([action, paginationState]) => {
          if (action.isRefresh) {
            return this._requestGetPath(action)
          } else if (this._isLastPagePaginationState(action, paginationState)) {
            return this._requestGetPath(action)
          } else {
            return [KnowledgeActions.getPathItemsPagedCancel()];
          }
        }
      )
    )
  }

  /**
   * @name getPathItemsByIdStart
   * @description
   * Receive the action started on TeamKnowledgeEffectsBase and use the latest state from knowledge path using id
   * to retrieve knowledge start by id data
   * @memberof TeamKnowledgeApiEffects
   * @param action$
   * @return {Observable<KnowledgePathListDto[] | any>}
   */
  getPathItemsByIdStart(action$: Observable<IKnowledgePathStartIdRequest>): Observable<KnowledgePathListDto[] | any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(selectKnowledgePathStart))),
      mergeMap(([action]) => this._requestGetPathItemByIdStart(action))
    );
  }

  getItemsSearched(action$: Observable<{
    toSearch: string
  }>): Observable<IOrgaResponse<KnowledgeArticleListDto[]> | any> {
    return action$.pipe(
      mergeMap((action) => {
        return this._requestItemsSearched(action)
      })
    )
  }

  getArticlePage(action$: Observable<{ articleId: number }>): Observable<IAttachmentKnowledgeArticle> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(selectAttachments))),
      mergeMap(([action]) => this._requestArticlePage(action))
    );
  }

  /**
   * @name _requestItemsSearched
   * @description
   * request the data to the document service to search by text
   * @memberof TeamKnowledgeApiEffects
   * @param action
   * @private
   */
  private _requestItemsSearched(action: {
    toSearch: string
  }): Observable<IOrgaResponse<KnowledgeArticleListDto[]> | any> {
    return this._sharedKnowledgeService.getBySearch(action.toSearch!).pipe(
      mergeMap((data: IOrgaResponse<KnowledgeArticleListDto[]>) => {
        return [KnowledgeActions.getItemsSearchedSuccess({ data })]
      }),
      catchError((error) => {
        return [KnowledgeActions.getItemsSearchedFail({ error })]
      })
    )
  }


  /**
   * @name _isLastPagePaginationState
   * @description
   * Check if there is not paging information or if the pageNumber in the action is
   * less than or equal to the total number of pages available in the paginationState
   * @memberof TeamKnowledgeApiEffects
   * @param action
   * @param state
   * @private
   * @return {boolean}
   */
  private _isLastPagePaginationState(action: IKnowledgePathRequestPagination, state: IPagination): boolean {
    return !state || action.params.pageNumber <= state.totalPages
  }

  /**
   * @name _requestGetPath
   * @description
   * Request the data to TeamKnowledgeService and map the value to launch and pass throw the action need it
   * @memberof TeamKnowledgeApiEffects
   * @param action
   * @private
   * @return {Observable<IOrgaResponse<KnowledgePathListDto[]> | any>}
   */
  private _requestGetPath(action: IKnowledgePathRequestPagination): Observable<IOrgaResponse<KnowledgePathListDto[]> | any> {
    return this._sharedKnowledgeService.getPathItems(action.params).pipe(
      map((data: IOrgaResponse<KnowledgePathListDto[]>) => {
        return action.isRefresh
          ? KnowledgeActions.getPathItemsPagedRefresh({ data })
          : KnowledgeActions.getPathItemsPagedSuccess({ data });
      }),
      catchError((error) => {
        return throwError(() => KnowledgeActions.getPathItemsPagedFail({ error }));
      })
    );
  }

  /**
   * @name _requestGetPathItemByIdStart
   * @description
   * Request the data to TeamKnowledgeService by id and map the value to launch and pass throw the action need it
   * @memberof TeamKnowledgeApiEffects
   * @param action
   * @private
   * @return {Observable<KnowledgePathStartDto | any>}
   */
  private _requestGetPathItemByIdStart(action: IKnowledgePathStartIdRequest): Observable<KnowledgePathStartDto | any> {
    return this._sharedKnowledgeService.getPathItemByIdStart(action.id).pipe(
      map((data: KnowledgePathStartDto) => {
        return action.isRefresh
          ? KnowledgeActions.getPathItemsByIdStartRefresh({ data })
          : KnowledgeActions.getPathItemsByIdStartSuccess({ data })
      }),
      catchError((error) => [KnowledgeActions.getPathItemsByIdStartFail({ error })])
    );
  }

  /**
   * @name _requestArticlePage
   * @param action
   * @private
   */
  private _requestArticlePage(action: { articleId: number }): Observable<IAttachmentKnowledgeArticle | any> {
    return this._sharedKnowledgeService.getArticle(action.articleId!).pipe(
      map((data) => KnowledgeActions.getArticlePageSuccess({ data: data })
      ),
      catchError((error) => [KnowledgeActions.getArticlePageFail({ error })]
      )
    );
  }

}
