<form [formGroup]="form" (ngSubmit)="submitForm()">
  <ion-list>
    <ion-item class="ion-no-padding">
      <ion-input label-placement="stacked"
                 [label]="'LOGIN.FORM-PASSWORD.LABELS.EMAIL' | translate"
                 formControlName="email" type="email" readonly="true">
      </ion-input>
    </ion-item>

    <ion-item class="ion-no-padding" *ngIf="loginData">
      <ion-input label-placement="stacked"
                 [label]="'LOGIN.FORM-PASSWORD.LABELS.PASSWORD_CURRENT' | translate"
                 formControlName="currentPassword" type="field" readonly="true">
      </ion-input>
    </ion-item>

    <ion-item class="ion-no-padding">
      <ion-input label-placement="stacked"
                 [label]="'LOGIN.FORM-PASSWORD.LABELS.PASSWORD_NEW' | translate" formControlName="newPassword"
                 type="password">
      </ion-input>
    </ion-item>

    <div class="form-errors" *ngIf="form.get('newPassword')?.hasError('minlength')">
      <ion-text class="ion-native" color="danger">
        {{ "LOGIN.FORM-PASSWORD.ERRORS.MINLENGTH" | translate }}
      </ion-text>
    </div>

    <ion-item class="ion-no-padding">
      <ion-input label-placement="stacked"
                 [label]="'LOGIN.FORM-PASSWORD.LABELS.PASSWORD_NEW_CONFIRM' | translate"
                 formControlName="confirmPassword" type="password"
                 required="required">

      </ion-input>
    </ion-item>

    <div class="form-errors">
      <ion-text class="ion-native" color="danger"
                *ngIf="form.get('confirmPassword')!.hasError('minlength')">
        {{ "LOGIN.FORM-PASSWORD.ERRORS.MINLENGTH" | translate }}
      </ion-text>
      <ion-text class="ion-native" color="danger"
                *ngIf="form.touched && form.hasError('passwordMismatch')">
        {{ "LOGIN.FORM-PASSWORD.ERRORS.MISMATCH" | translate }}
      </ion-text>
    </div>

    <ion-button *ngIf="isSubmitNeed" expand="full" type="submit" [disabled]="!form.valid"
                [innerText]="'LOGIN.CHANGE_PASSWORD.FORM.BUTTONS.SUBMIT' | translate">

    </ion-button>
  </ion-list>
</form>
