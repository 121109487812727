import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {FilterDto, InfoListDto} from "@server-models";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {map} from "rxjs/operators";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {IRequestOptions} from "@shared/interfaces/request-options.interface";
import {IInfosMyReadDto} from "@team/pages/infos/interfaces/infos-my-read-dto.interface";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class TeamInfoService extends CoreHttpClientApiBaseService {

  /**
   * @name pagePostPaginated
   * @memberof TeamInfoService
   * @description
   * Make a post request to Orga server to retrieve the infos data parameterized and with body
   * @param paramsPaginated
   * @param body
   */
  pagePostPaginated(paramsPaginated: IPaginationParams, body: FilterDto[]): Observable<IOrgaResponse<InfoListDto[]>> {
    const options: IRequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        SortField: paramsPaginated.sortField as string,
        Sort: paramsPaginated.sort!.toString()
      }
    }
    return super.post<IOrgaResponse<InfoListDto[]>>(`/orga/v2/info/filter`,
      undefined, body, options).pipe(
      map((res: IOrgaResponse<InfoListDto[]>) => res)
    );
  }

  /**
   * @name postMyReadById
   * @memberof TeamInfoService
   * @description
   * Make a post request to Orga server to mark an info as read by id
   * @param id
   */
  postMyReadById(id: number): Observable<IOrgaResponse<IInfosMyReadDto>> {
    return super.post<IOrgaResponse<IInfosMyReadDto>>(`/orga/v2/me/info/${ id }/read`,
      undefined, undefined, undefined);
  }

  postSearchPaginated(searchText: string, paramsPaginated: IPaginationParams,
                      body: FilterDto[]): Observable<IOrgaResponse<InfoListDto[]>> {
    const options = {
      params: {
        q: searchText,
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        SortField: paramsPaginated.sortField as string,
        Sort: paramsPaginated.sort!.toString()
      }
    }
    return super.post<IOrgaResponse<InfoListDto[]>>(`/orga/v2/info/search/filter`, undefined, body, options);
  }
}
