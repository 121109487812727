import {Injectable} from "@angular/core";
import {DefaultDataService, DefaultDataServiceConfig, HttpUrlGenerator} from "@ngrx/data";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ChatByFormDefinitionDto} from "@server-models";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";

@Injectable({
  providedIn: 'root',
})
export class TeamChatDataService extends DefaultDataService<ChatByFormDefinitionDto> {
  constructor(http: HttpClient, httpUrlGenerator: HttpUrlGenerator, private _config: DefaultDataServiceConfig) {
    super('Chat', http, httpUrlGenerator, _config);
  }

  override getAll(): Observable<ChatByFormDefinitionDto[]> {
    return this.http.get<IOrgaResponse<ChatByFormDefinitionDto[]>>(`${ this._config.root }/v2/ChatByFormDefinition`)
      .pipe(
        map((res: IOrgaResponse<ChatByFormDefinitionDto[]>) => {
          return res.items;
        })
      );
  }
}
