import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {select, Store} from "@ngrx/store";
import {Observable} from "rxjs";
import {TeamSideBarComponent} from "@team/components/side-bar/team-side-bar.component";
import {map} from "rxjs/operators";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

@Component({
  selector: 'app-team-main',
  templateUrl: './team-main.page.html',
  styleUrls: ['./team-main.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, TeamSideBarComponent]
})
export class TeamMainPage implements OnInit {

  isLoading$!: Observable<boolean>;
  currentLogin$!: Observable<boolean>;

  constructor(
    private _store: Store
  ) {
    this.isLoading$ = this._store.select(TeamLoginSelectors.selectIsLoading);

  }

  ngOnInit() {
    this.currentLogin$ = this._store.pipe(select(TeamLoginSelectors.selectCurrentLogin), map((login) => !!login));
  }
}
