import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {TeamInfoBaseEffects} from "@team/pages/infos/store/team-info-base.effects";
import {TeamInfoService} from "@team/pages/infos/services/team-info.service";
import {TeamInfoActions} from "@team/pages/infos/store/team-info.actions-type";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {InfoListDto, TenantInfoDto, TokenDto} from "@server-models";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {selectInfoPageList, selectInfoPagePagination} from "@team/pages/infos/store/team-info.selectors";
import {ToastControllerService} from "@shared/services/toast-controller.service";
import {TranslateService} from "@ngx-translate/core";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {IInfosMyReadDto} from "@team/pages/infos/interfaces/infos-my-read-dto.interface";
import {IInfosRequestPagination} from "@team/pages/infos/interfaces/infos-request-pagination.interface";

@Injectable({
  providedIn: 'root'
})
export class TeamInfoApiEffects extends TeamInfoBaseEffects {

  constructor(
    actions$: Actions,
    store: Store,
    private _infosService: TeamInfoService,
    private _toastControllerService: ToastControllerService,
    private _translateService: TranslateService
  ) {
    super(store, actions$);
  }

  /**
   * @name postMyRead
   * @memberof TeamInfoApiEffects
   * @description
   * Receive the action started on TeamInfosEffectsBase and use the latest state from infos.team
   * to use the userName for the toast
   * @param action$
   */
  postMyRead(action$: Observable<{ id: number }>): Observable<(IOrgaResponse<IInfosMyReadDto> | any)> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(TeamLoginSelectors.selectToken))),
      withLatestFrom(this.store.pipe(select(selectInfoPageList))),
      mergeMap(([[currentId, appState], currentInfoList]) =>
        this._requestPostMyRead(currentId, this._getCurrentUserName(appState), currentInfoList)
      )
    );
  }

  /**
   * @name postPaginated
   * @memberof TeamInfoApiEffects
   * @description
   * Receive the action started on TeamInfosEffectsBase and use the latest state from infos.pagination
   * to check if there is more pages available and call a function to request infos data
   * @param action$
   * @return {Observable<(IOrgaResponse<InfoListDto[]> | any)>}
   */
  postPaginated(action$: Observable<IInfosRequestPagination>): Observable<(IOrgaResponse<InfoListDto[]> | any)> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(selectInfoPagePagination))),
      mergeMap(([action, paginationState]) => {
          if (action.isRefresh) {
            return this._requestPostPaginated(action);
          } else if (this._isLastPagePaginationState(action, paginationState)) {
            return this._requestPostPaginated(action);
          } else {
            return [TeamInfoActions.postItemsPaginatedCancel()];
          }
        }
      )
    );
  }

  /**
   * @name _isLastPagePaginationState
   * @memberof TeamInfoApiEffects
   * @description
   * Check if there is not paging information or if the pageNumber in the action is
   * less than or equal to the total number of pages available in the paginationState
   * @param action
   * @param state
   * @private
   * @return {boolean}
   */
  private _isLastPagePaginationState(action: IInfosRequestPagination, state: IPagination): boolean {
    return !state || action.params.pageNumber <= state.totalPages
  }

  /**
   * @name _requestPostPaginated
   * @memberof TeamInfoApiEffects
   * @description
   * Request the data to infoService and map the value to launch and pass throw the action need it
   * @param action
   * @private
   */
  private _requestPostPaginated(action: IInfosRequestPagination): Observable<(IOrgaResponse<InfoListDto[]> | any)> {
    return this._infosService.pagePostPaginated(action.params, action.body).pipe(
      map((data: IOrgaResponse<InfoListDto[]>) =>
        action.isRefresh
          ? TeamInfoActions.postItemsPaginatedRefresh({ data })
          : TeamInfoActions.postItemsPaginatedSuccess({ data })
      ),
      catchError((error) => [TeamInfoActions.postItemsPaginatedFail({ error })])
    )
  }

  /**
   * @name _requestPostMyRead
   * @memberof TeamInfoApiEffects
   * @description
   * Request the data to infoService, call the toast service and launch the action need it
   * @param action
   * @param userName
   * @param currentInfoList
   * @private
   */
  private _requestPostMyRead(action: {
    id: number
  }, userName: string, currentInfoList: InfoListDto[]): Observable<(IOrgaResponse<IInfosMyReadDto> | any)> {
    return this._infosService.postMyReadById(action.id).pipe(
      map((data: IOrgaResponse<IInfosMyReadDto>) => {
        const updatedItems = this._updateReadAtItem(currentInfoList, action.id);
        this._toastControllerService.observableToast({ message: this._formatMyReadToastMessage(userName) });
        return TeamInfoActions.postItemsMyReadSuccess({ updatedItems: updatedItems, data })
      }),
      catchError((error) => [TeamInfoActions.postItemsMyReadFail({ error })])
    )
  }

  postItemsSearchedPaginated(action$: Observable<{
    searchTerm: string;
    requestParam: IInfosRequestPagination
  }>): Observable<(IOrgaResponse<InfoListDto[]> | any)> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(selectInfoPagePagination))),
      mergeMap(([action, paginationState]) => {
          if (action.requestParam.isRefresh) {
            return this._requestPostSearchedPaginated(action);
          } else if (this._isLastPagePaginationState(action.requestParam, paginationState)) {
            return this._requestPostSearchedPaginated(action);
          } else {
            return [TeamInfoActions.postItemsSearchedPaginatedCancel()];
          }
        }
      )
    );
  }

  private _requestPostSearchedPaginated(action: {
    searchTerm: string;
    requestParam: IInfosRequestPagination
  }): Observable<(IOrgaResponse<InfoListDto[]> | any)> {
    return this._infosService.postSearchPaginated(action.searchTerm, action.requestParam.params, action.requestParam.body).pipe(
      map((data: IOrgaResponse<InfoListDto[]>) =>
        action.requestParam.isRefresh
          ? TeamInfoActions.postItemsSearchedPaginatedRefresh({ data })
          : TeamInfoActions.postItemsSearchedPaginatedSuccess({ data })
      ),
      catchError((error) => [TeamInfoActions.postItemsSearchedPaginatedFail({ error })])
    )
  }

  /**
   * @name _getCurrentUserName
   * @memberof TeamInfoApiEffects
   * @description
   * return the current name of the user
   * @param appState
   * @private
   */
  private _getCurrentUserName(appState: TokenDto | null | undefined): string {
    const currentInfoTenants = appState?.info?.tenants as TenantInfoDto[];
    return currentInfoTenants[0].displayName as string;
  }

  /**
   * @name _formatMyReadToastMessage
   * @memberof TeamInfoApiEffects
   * @description
   * Format the message to send the toast
   * @param userName
   * @private
   */
  private _formatMyReadToastMessage(userName: string): string {
    const preSentence = this._translateService.instant("GENERAL.TOAST.INFOS.CARDS.READ.USER");
    const postSentence = this._translateService.instant("GENERAL.TOAST.INFOS.CARDS.READ.READY");
    return `${ preSentence } ${ userName }: ${ postSentence }.`;
  }

  /**
   * @name _updateReadAtItem
   * @description
   * find the itemId in the existing items and set a temporal date in isRead
   * to be able to filter by isRead values since the request don't return anything more than just success
   * @memberof TeamInfoApiEffects
   * @param items
   * @param itemId
   * @private
   */
  private _updateReadAtItem(items: InfoListDto[], itemId: number): InfoListDto[] {
    const itemIndex = items.findIndex(item => item.infoId === itemId);

    if (itemIndex === -1) {
      return items;
    }

    const updatedItems = [...items];
    updatedItems[itemIndex] = {
      ...updatedItems[itemIndex],
      isRead: !updatedItems[itemIndex].isRead
    };
    return updatedItems;
  }
}
