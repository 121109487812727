import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TeamSideBarState} from "@team/components/side-bar/store/team-side-bar.state";

export const teamSideBarStoreSelect = createFeatureSelector<TeamSideBarState>('sideBar');

export const selectSideBar = createSelector(
  teamSideBarStoreSelect,
  (state: TeamSideBarState) => state
);

export const selectSideBarIsLoading = createSelector(
  teamSideBarStoreSelect,
  (state: TeamSideBarState) => state.isLoading
);

export const selectSideBarIsLoadingId = createSelector(
  teamSideBarStoreSelect,
  (state: TeamSideBarState) => state.isLoadingId
);

export const selectSideBarLogo = createSelector(
  teamSideBarStoreSelect,
  (state: TeamSideBarState) => state.logo
);
