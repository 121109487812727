import {Injectable} from "@angular/core";
import {IssueContentType, IssueType} from "@server-models";
import {TeamIssueMessageApiService} from "@team/pages/issues/services/team-issue-message-api.service";
import {TeamIssueMissionApiService} from "@team/pages/issues/services/team-issue-mission-api.service";
import {TeamIssueTicketApiService} from "@team/pages/issues/services/team-issue-ticket-api.service";
import {
  TeamIssueWinterhalterServiceCallApiService
} from "@team/pages/issues/services/winterhalter/team-issue-winterhalter-service-call-api.service";
import {IIssueService} from "@shared/components/issues/interfaces/issue-service.interface";
import {
  TeamIssueWinterhalterProductOrderApiService
} from "@team/pages/issues/services/winterhalter/team-issue-winterhalter-product-order-api.service";
import {TeamIssueControlMessageApiService} from "@team/pages/issues/services/team-issue-control-message-api.service";

@Injectable({
  providedIn: 'root',
})
export class TeamIssueService {
  constructor(
    private _teamIssuesMessageApiService: TeamIssueMessageApiService,
    private _teamIssuesMissionApiService: TeamIssueMissionApiService,
    private _teamIssuesTicketApiService: TeamIssueTicketApiService,
    private _teamIssuesControlMessageApiService: TeamIssueControlMessageApiService,
    private _teamIssuesWinterhalterServiceCallApiService: TeamIssueWinterhalterServiceCallApiService,
    private _teamIssuesWinterhalterProductOrderService: TeamIssueWinterhalterProductOrderApiService,
  ) {

  }

  getApiServiceByIssueType(issueType: IssueType): IIssueService {
    switch (issueType) {
      case IssueType.Message:
        return this._teamIssuesMessageApiService;
      case IssueType.Mission:
        return this._teamIssuesMissionApiService;
      case IssueType.Ticket:
        return this._teamIssuesTicketApiService;
      case IssueType.ControlMessage:
        return this._teamIssuesControlMessageApiService;
      case IssueType.WinterhalterServiceCall:
        return this._teamIssuesWinterhalterServiceCallApiService;
      case IssueType.WinterhalterProductOrder:
        return this._teamIssuesWinterhalterProductOrderService;
      default:
        throw new Error(`Api service, Unsupported issue type: ${ issueType }`);
    }
  }

  getApiServiceByIssueContentType(issueContentType: IssueContentType): IIssueService {
    switch (issueContentType) {
      case IssueContentType.Message:
        return this._teamIssuesMessageApiService;
      case IssueContentType.Mission:
        return this._teamIssuesMissionApiService;
      case IssueContentType.Ticket:
        return this._teamIssuesTicketApiService;
      case IssueContentType.WinterhalterServiceCall:
        return this._teamIssuesWinterhalterServiceCallApiService;
      case IssueContentType.WinterhalterProductOrder:
        return this._teamIssuesWinterhalterProductOrderService;
      default:
        throw new Error(`Api service, Unsupported issue content type: ${ issueContentType }`);
    }
  }


}
