import {Component, Input, OnInit} from '@angular/core';
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedPinInputComponent} from "@shared/components/pin-input/shared-pin-input.component";
import {select, Store} from "@ngrx/store";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {TeamLoginState} from "@team/pages/login/store/team-login.state";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {
  TeamLoginRegisterUserFormComponent
} from "@team/pages/login/components/request-register/components/user-form/team-login-register-user-form.component";
import {RegisterUserDto} from "@server-models";

@Component({
  selector: 'app-team-login-pin-register-user-dialog',
  templateUrl: './team-login-pin-register-user-dialog.component.html',
  styleUrls: ['./team-login-pin-register-user-dialog.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedSafePipe,
    SharedPinInputComponent,
    SharedUserHtmlComponent,
    TeamLoginRegisterUserFormComponent
  ],
  standalone: true
})
export class TeamLoginPinRegisterUserDialogComponent implements OnInit {

  @Input() passwordBase64: string;
  @Input() email: string;

  pinForm: FormGroup;
  currentPin: string;
  isSubmitDisabled: boolean;
  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;

  constructor(
    private _modalControllerService: SharedModalControllerService,
    private _store: Store<TeamLoginState>
  ) {
    this.passwordBase64 = "";
    this.email = "";
    this.currentPin = "";
    this.pinForm = new FormGroup({});
    this.isSubmitDisabled = true;
    this.isLoading$ = of(false);
    this.isLoadingId$ = of(NaN);
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoadingId));
  }

  /**
   * @name exit
   * @description
   * close the modal
   * @memberof TeamLoginPinRegisterUserDialogComponent
   */
  exit(): void {
    this._store.dispatch(TeamLoginActions.initRegistrationGuestDialogClose());
    this._modalControllerService.closeModal();
  }

  /**
   * @name submit
   * @description
   * dispatch the action on submit to complete the invitation
   * @memberof TeamLoginPinRegisterUserDialogComponent
   */
  submit(): void {
    const dispatchData: RegisterUserDto = {
      passwordBase64: this.passwordBase64,
      pinFromEmail: this.currentPin,
      email: this.email
    };

    this._store.dispatch(TeamLoginActions.registerGuestAsUser({ data: dispatchData, isLoadingId: this.currentPin }))
  }

  /**
   * @name formatPin
   * @description
   * format the pin to a full number as string
   * @memberof TeamLoginPinRegisterUserDialogComponent
   * @param pinArray
   * @returns {string}
   */
  formatPin(pinArray: number[]): string {
    return parseInt(pinArray.join('')).toString();
  }

  /**
   * @name checkLoadingButton
   * @description
   * check isLoading by id
   * @memberof TeamLoginPinRegisterUserDialogComponent
   * @param buttonId
   */
  checkLoadingButton(buttonId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (buttonId! === id) && isLoading)
    );
  }

  /**
   * @name onPinFormChanged
   * @description
   * update this currentpin and form values on form change
   * @memberof TeamLoginPinRegisterUserDialogComponent
   * @param pinForm
   */
  onPinFormChanged(pinForm: FormGroup): void {
    this.currentPin = this.formatPin(pinForm.get('pin')?.value);
    this.pinForm = pinForm;
    this.pinForm.setControl('pin', pinForm.get('pin'));
    this.isSubmitDisabled = this.pinForm.invalid;
  }

  /**
   * @name requestPinAgain
   * @description
   * make the pin request again
   * @memberof TeamLoginPinRegisterUserDialogComponent
   */
  requestPinAgain(): void {
    this._store.dispatch(TeamLoginActions.registerGuestPinRequest({
      registerGuest: {
        isRetry: true,
        email: this.email,
        passwordBase64: this.passwordBase64
      },
      isLoadingId: this.email
    }));
  }
}
