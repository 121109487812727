import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule, NavController} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {ActivatedRoute} from "@angular/router";
import {KnowledgeArticleStartDto} from "@server-models";
import {
  SharedKnowledgeArticleDetailStartComponent
} from "@shared/components/knowledge-article-detail-start/shared-knowledge-article-detail-start.component";


@Component({
  selector: 'app-team-article',
  templateUrl: './team-article.page.html',
  styleUrls: ['./team-article.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, SharedKnowledgeArticleDetailStartComponent],
})
export class TeamArticlePage implements OnInit {

  article: KnowledgeArticleStartDto;

  constructor(
    private _activatedRouter: ActivatedRoute,
    private _nav: NavController
  ) {
    this.article = {};

  }

  ngOnInit() {
    this.article = this._activatedRouter.snapshot.data['article'];
  }

  back() {
    this._nav.back()
  }

}
