import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {mergeMap, Observable, throwError, withLatestFrom} from "rxjs";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {OrgaFolderItemDto} from "@server-models";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {catchError, map} from "rxjs/operators";
import {SharedDocumentEffectsBase} from "@shared/components/documents/store/shared-document.effects";
import {SharedDocumentsSelectors} from "@shared/components/documents/store/shared-document.selectors-type";
import {
  IDocumentsRequestPagination
} from "@shared/components/documents/interfaces/document-request-pagination.interface";
import {SharedDocumentsActions} from "@shared/components/documents/store/shared-document.actions-type";
import {SharedDocumentApiService} from "@shared/components/documents/services/shared-document-api.service";


@Injectable({
  providedIn: 'root'
})
export class SharedDocumentApiEffects extends SharedDocumentEffectsBase {

  constructor(
    actions$: Actions,
    store: Store,
    private _documentsApiService: SharedDocumentApiService
  ) {
    super(store, actions$);
  }

  /**
   * @name getPagination
   * @description
   * handle the action to forward to need it request
   * @memberof SharedDocumentApiEffects
   * @param action$
   */
  getPagination(action$: Observable<IDocumentsRequestPagination>): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedDocumentsSelectors.selectDocumentsPagePagination))),
      mergeMap(([action, paginationState]) => {
          if (action.isRefresh) {
            return this._requestGetPagination(action)
          } else if (this._isLastPagePaginationState(action, paginationState)) {
            return this._requestGetPagination(action)
          } else {
            return [SharedDocumentsActions.getItemsPaginatedCancel()];
          }
        }
      )
    );
  }


  getItemsSearched(action$: Observable<{
    toSearch: string,
    path: string
  }>): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any> {
    return action$.pipe(
      mergeMap((action) => {
        return this._requestItemsSearched(action)
      })
    )
  }

  getItemsPersonalPaginated(action$: Observable<IDocumentsRequestPagination>): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(SharedDocumentsSelectors.selectDocumentsPagePagination))),
      mergeMap(([action, paginationState]) => {
          if (action.isRefresh) {
            return this._requestGetPersonalPagination(action)
          } else if (this._isLastPagePaginationState(action, paginationState)) {
            return this._requestGetPersonalPagination(action)
          } else {
            return [SharedDocumentsActions.getItemsPaginatedCancel()];
          }
        }
      )
    );
  }

  private _requestGetPersonalPagination(action: IDocumentsRequestPagination): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any> {
    return this._documentsApiService.getDocumentsPersonalItems(action.tenantId!, action.path, action.params).pipe(
      map((data: IOrgaResponse<OrgaFolderItemDto[]>) => {
        return action.isRefresh
          ? SharedDocumentsActions.getItemsPersonalPaginatedRefresh({ data })
          : SharedDocumentsActions.getItemsPersonalPaginatedSuccess({ data });
      }),
      catchError((error) => {
        return throwError(() => SharedDocumentsActions.getItemsPersonalPaginatedFail({ error }));
      })
    );
  }

  /**
   * @name _requestItemsSearched
   * @description
   * request the data to the document service to search by text
   * @memberof SharedDocumentApiEffects
   * @param action
   * @private
   */
  private _requestItemsSearched(action: {
    toSearch: string,
    path: string
  }): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any> {
    return this._documentsApiService.getDocumentsBySearch(action.toSearch!, action.path).pipe(
      mergeMap((data: IOrgaResponse<OrgaFolderItemDto[]>) => {
        return [SharedDocumentsActions.getItemsSearchedSuccess({ data })]
      }),
      catchError((error) => {
        return [SharedDocumentsActions.getItemsSearchedFail({ error })]
      })
    )
  }


  /**
   * @name _isLastPagePaginationState
   * @description
   * Check if there is not paging information or if the pageNumber in the action is
   * less than or equal to the total number of pages available in the paginationState
   * @memberof SharedDocumentApiEffects
   * @param action
   * @param state
   * @private
   */
  private _isLastPagePaginationState(action: IDocumentsRequestPagination, state: IPagination): boolean {
    return !state || action.params.pageNumber <= state.totalPages
  }

  /**
   * @name _requestGetPagination
   * @description
   * request the data to the document service and redirect to need it action
   * @memberof SharedDocumentApiEffects
   * @param action
   * @private
   */
  private _requestGetPagination(action: IDocumentsRequestPagination): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any> {
    return this._documentsApiService.getDocumentsItems(action.tenantId!, action.path, action.params).pipe(
      map((data: IOrgaResponse<OrgaFolderItemDto[]>) => {
        return action.isRefresh
          ? SharedDocumentsActions.getItemsPaginatedRefresh({ data })
          : SharedDocumentsActions.getItemsPaginatedSuccess({ data });
      }),
      catchError((error) => {
        return throwError(() => SharedDocumentsActions.getItemsPaginatedFail({ error }));
      })
    );
  }

}
