import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {
  sharedPasswordMatchValidator
} from "@shared/custom-validators/shared-custom-validator-passwords-match.validator";

@Component({
  selector: 'app-shared-form-password',
  templateUrl: './shared-form-password.component.html',
  styleUrls: ['./shared-form-password.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  standalone: true
})
export class SharedFormPasswordComponent implements OnInit {

  form!: FormGroup;

  @Input() loginData?: any;
  @Input() newPasswordData?: { token: string, email: string };
  @Input() isSubmitNeed: boolean;

  @Output() formChange: EventEmitter<FormGroup>;
  @Output() submitChange: EventEmitter<FormGroup>;

  constructor(
    private _formBuilder: FormBuilder,
  ) {
    this.isSubmitNeed = true;
    this.formChange = new EventEmitter<FormGroup>();
    this.submitChange = new EventEmitter<FormGroup>();
  }

  ngOnInit() {
    this.loginData ? this.initializeChangePasswordForm() : this.initializeNewPasswordForm();
    this.form.valueChanges.subscribe(() => {
      this.formChange.emit(this.form);
    });
  }

  initializeChangePasswordForm() {
    this.loginData.password = atob(this.loginData.passwordBase64);

    this.form = this._formBuilder.group({
      email: [this.loginData.email, Validators.required],
      currentPassword: [this.loginData.password, Validators.required],
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    }, { validators: [sharedPasswordMatchValidator('newPassword', 'confirmPassword')] });
  }

  initializeNewPasswordForm() {
    this.form = this._formBuilder.group({
      email: [this.newPasswordData?.email, Validators.required],
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])]
    }, { validators: [sharedPasswordMatchValidator('newPassword', 'confirmPassword')] })
  }

  submitForm() {
    this.submitChange.emit(this.form);
  }

}


