<form [formGroup]="invitationCodeForm" (ngSubmit)="submitForm()">
  <app-shared-custom-property-form-input-text
    [label]="'TEAM.WELCOME.DIALOGS.INVITATION_GUEST.INPUTS.LASTNAME.LABEL' | translate"
    [formControl]="getControl('lastName')"
    [isRequired]="isRequired('lastName')"></app-shared-custom-property-form-input-text>
  <app-shared-custom-property-form-input-text
    [label]="'TEAM.WELCOME.DIALOGS.INVITATION_GUEST.INPUTS.PIN.LABEL' | translate"
    [formControl]="getControl('pin')"
    [maxLength]="5"
    [isRequired]="isRequired('pin')"></app-shared-custom-property-form-input-text>
</form>
