import {Injectable} from "@angular/core";
import {CoreBaseEffects} from "@core/core-base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable} from "rxjs";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {OrgaFolderItemDto} from "@server-models";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {
  IDocumentsRequestPagination
} from "@shared/components/documents/interfaces/document-request-pagination.interface";
import {SharedDocumentsActions} from "@shared/components/documents/store/shared-document.actions-type";

@Injectable({
  providedIn: 'root'
})
export abstract class SharedDocumentEffectsBase extends CoreBaseEffects {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract getPagination(action$: Observable<IDocumentsRequestPagination>): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any>;

  abstract getItemsSearched(action$: Observable<{
    toSearch: string,
    path: string
  }>): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any>;

  abstract getItemsPersonalPaginated(action$: Observable<{
    tenantId: number;
    params: IPaginationParams;
    isRefresh: boolean;
  }>): Observable<IOrgaResponse<OrgaFolderItemDto[]> | any>;

  getPagination$ = createEffect(() => this.actions().pipe(
    ofType(SharedDocumentsActions.getItemsPaginated),
    action => this.getPagination(action)
  ));

  getItemsSearched$ = createEffect(() => this.actions().pipe(
    ofType(SharedDocumentsActions.getItemsSearched),
    action => this.getItemsSearched(action)
  ));

  getItemsPersonalPaginated$ = createEffect(() => this.actions().pipe(
    ofType(SharedDocumentsActions.getItemsPersonalPaginated),
    action => this.getItemsPersonalPaginated(action)
  ));
}
