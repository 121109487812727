import {Injectable} from "@angular/core";
import {IAlertControllerCreate} from "@shared/interfaces/alert-controller-create.interface";
import {CoreErrorHandlerBaseService} from "@core/services/error-handler-base/core-error-handler-base.service";

@Injectable({
  providedIn: 'root'
})
export class TeamErrorHandlerLoginService extends CoreErrorHandlerBaseService {
  override _formatAlertFor422(status: number, name: string, message: string): IAlertControllerCreate {
    const errorMessage = this._filterErrorStatus(status);
    const changePasswordAlert = this._formatChangePasswordAlert(status, name, message);

    return {
      header: `${ this._errorText }: ${ status.toString() }`,
      message: errorMessage,
      buttons: [
        {
          text: this._translateService.instant("GENERAL.ALERT.BUTTONS.DETAILS"),
          handler: () => {
            this._alertControllerService.observableAlert(changePasswordAlert);
          }
        },
        {
          text: this._translateService.instant("GENERAL.ALERT.BUTTONS.CHANGE_PASSWORD"),
          handler: () => {
            this._router.navigateByUrl("team/login/change-password");
          }
        }
      ]
    };
  }

  override _formatAlertFor400(status: number, name: string, message: any): IAlertControllerCreate {
    let errorMessage = this._filterErrorStatus(status);
    if (!!message[0]?.Code) {
      errorMessage = this._getAllErrors(message);
    }

    return {
      header: this._translateService.instant("LOGIN.TEAM.ERRORS.400"),
      subHeader: '',
      message: errorMessage,
      buttons: [
        { text: 'OK' }
      ]
    };
  }

  private _formatChangePasswordAlert(status: number, name: string, message: string): IAlertControllerCreate {
    return {
      header: `${ this._errorText }: ${ status.toString() }`,
      subHeader: name,
      message: message,
      buttons: [
        { text: 'OK' }
      ],
    };
  }
}
