import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {TranslateModule} from "@ngx-translate/core";
import {SharedShowHidePasswordComponent} from "@shared/components/show-hide-password/shared-show-hide-password.component";
import {SharedCustomPropertyFormInputTextComponent} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";
import {
  TeamLoginInvitationCodeFormComponent
} from "@team/pages/login/components/invitation-code/components/form/team-login-invitation-code-form.component";
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {select, Store} from "@ngrx/store";
import {Observable, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {
  SharedCustomPropertyFormInputNumericComponent
} from "@shared/components/custom-property-form/components/input/components/number/shared-custom-property-form-input-numeric.component";

@Component({
  selector: 'app-team-login-invitation-code-dialog',
  templateUrl: './team-login-invitation-code-dialog.component.html',
  styleUrls: ['./team-login-invitation-code-dialog.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedShowHidePasswordComponent,
    SharedCustomPropertyFormInputTextComponent,
    SharedCustomPropertyFormInputNumericComponent,
    TeamLoginInvitationCodeFormComponent
  ],
  standalone: true
})
export class TeamLoginInvitationCodeDialogComponent implements OnInit {

  @Input() code: string;
  @Output() toReset: EventEmitter<any>;
  @Output() userSubmit: EventEmitter<{ input: { username: string, password: string } }>;

  invitationCodeFormShadow: FormGroup;
  pin: string;
  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;

  constructor(
    private _formBuilder: FormBuilder,
    private _modalControllerService: SharedModalControllerService,
    private _store: Store
  ) {
    this.code = "";
    this.pin = "emailPinId";
    this.isLoading$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoadingId));
    this.toReset = new EventEmitter<any>();
    this.userSubmit = new EventEmitter<{ input: { username: string, password: string } }>();
    this.invitationCodeFormShadow = this._formBuilder.group({
      lastName: ['', [Validators.required]],
      pin: ['', [Validators.required]]
    });
  }

  getControl(controlName: string) {
    return this.invitationCodeFormShadow.get(controlName) as FormControl;
  }


  ngOnInit() {
  }

  onFormChange(form: FormGroup): void {
    this.invitationCodeFormShadow = form;
  }

  exit(): void {
    this._store.dispatch(TeamLoginActions.invitationCodeGuestAcceptCancel());
    this._modalControllerService.closeModal();
  }

  submit(): void {
    const lastName = this.invitationCodeFormShadow.value.lastName;
    const pin = this.invitationCodeFormShadow.value.pin.toString();

    this.dispatchInvitationCodeCheck(lastName, pin);
  }

  dispatchInvitationCodeCheck(lastName: string, pin: string): void {
    this._store.dispatch(TeamLoginActions.invitationCodeGuestAccept({
      code: this.code,
      lastName,
      pin,
      isLoadingId: this.pin
    }))
  }

  checkLoadingButton(pinId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (this.pin! === id) && isLoading)
    );
  }

}
