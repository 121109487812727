<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/"></ion-back-button>
    </ion-buttons>
    <ion-title [innerText]="'LOGIN.CHANGE_PASSWORD.TITLE' | translate"></ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-spinner *ngIf="!isLoading$"></ion-spinner>
  <app-shared-form-password (submitChange)="onSubmitChange($event)" [loginData]="loginData"></app-shared-form-password>
</ion-content>
