import {createAction, props} from "@ngrx/store";
import {InfoListDto} from "@server-models";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {IInfosRequestPagination} from "@team/pages/infos/interfaces/infos-request-pagination.interface";
import {IInfosMyReadDto} from "@team/pages/infos/interfaces/infos-my-read-dto.interface";

export const postItemsPaginated = createAction(
  '[Infos-Team] Post Paginated',
  props<IInfosRequestPagination>()
);

export const postItemsPaginatedCancel = createAction(
  '[Infos-Team] Post Paginated Cancel',
);

export const postItemsPaginatedSuccess = createAction(
  '[Infos-Team] Post Paginated success',
  props<{ data: IOrgaResponse<InfoListDto[]> }>()
);

export const postItemsPaginatedFail = createAction(
  '[Infos-Team] Post Paginated fail',
  props<{ error: any }>()
);

export const postItemsPaginatedRefresh = createAction(
  '[Infos-Team] Post Paginated Refresh',
  props<{ data: IOrgaResponse<InfoListDto[]> }>()
);

export const postItemsMyRead = createAction(
  '[Infos-Team] Post My Read',
  props<{ id: number }>()
);

export const postItemsMyReadSuccess = createAction(
  '[Infos-Team] Post My Read Success',
  props<{ updatedItems: InfoListDto[], data: IOrgaResponse<IInfosMyReadDto> }>()
);

export const postItemsMyReadFail = createAction(
  '[Infos-Team] Post My Read Fail',
  props<{ error: any }>()
);

export const postItemsSearchedPaginated = createAction(
  '[Infos-Team] Post Searched Paginated',
  props<{ searchTerm: string; requestParam: IInfosRequestPagination }>()
);

export const postItemsSearchedPaginatedCancel = createAction(
  '[Infos-Team] Post Searched Paginated Cancel'
);

export const postItemsSearchedPaginatedRefresh = createAction(
  '[Infos-Team] Post Searched Paginated Refresh',
  props<{ data: IOrgaResponse<InfoListDto[]> }>()
);

export const postItemsSearchedPaginatedSuccess = createAction(
  '[Infos-Team] Post Searched Paginated Success',
  props<{ data: IOrgaResponse<InfoListDto[]> }>()
);

export const postItemsSearchedPaginatedFail = createAction(
  '[Infos-Team] Post Searched Paginated Fail',
  props<{ error: any }>()
);
