import {Component, Input, OnInit} from '@angular/core';
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedPinInputComponent} from "@shared/components/pin-input/shared-pin-input.component";
import {select, Store} from "@ngrx/store";
import {Observable, of, withLatestFrom} from "rxjs";
import {map} from "rxjs/operators";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {TeamLoginState} from "@team/pages/login/store/team-login.state";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {
  TeamLoginRegisterUserFormComponent
} from "@team/pages/login/components/request-register/components/user-form/team-login-register-user-form.component";
import {
  IDispatchRegisterGuest
} from "@team/pages/login/components/request-register/interfaces/dispatch-register-guest.interface";

@Component({
  selector: 'app-team-login-request-register-user-dialog',
  templateUrl: './team-login-request-register-user-dialog.component.html',
  styleUrls: ['./team-login-request-register-user-dialog.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedSafePipe,
    SharedPinInputComponent,
    SharedUserHtmlComponent,
    TeamLoginRegisterUserFormComponent
  ],
  standalone: true
})
export class TeamLoginRequestRegisterUserDialogComponent implements OnInit {

  @Input() currentEmail: string;

  registerUserShadowForm: FormGroup;
  isSubmitDisabled: boolean;
  isLoading$: Observable<boolean>;
  isLoadingId$: Observable<number | string>;
  buttonId: string;

  constructor(
    private _modalControllerService: SharedModalControllerService,
    private _store: Store<TeamLoginState>
  ) {
    this.registerUserShadowForm = new FormGroup({});
    this.isSubmitDisabled = true;
    this.isLoading$ = of(false);
    this.isLoadingId$ = of(NaN);
    this.buttonId = "submitRegister"
    this.currentEmail = "";
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoading));
    this.isLoadingId$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoadingId));
  }

  /**
   * @name exit
   * @description
   * close the modal
   * @memberof TeamLoginRequestRegisterUserDialogComponent
   */
  exit(): void {
    this._store.dispatch(TeamLoginActions.initRegistrationGuestDialogClose());
    this._modalControllerService.closeModal();
  }

  /**
   * @name submit
   * @description
   * dispatch the action on submit to complete the invitation
   * @memberof TeamLoginRequestRegisterUserDialogComponent
   */
  submit(): void {
    const password = this.registerUserShadowForm.value.password;
    const dispatchData: IDispatchRegisterGuest = {
      password: password,
      passwordBase64: btoa(password),
      confirmPassword: this.registerUserShadowForm.value.confirmPassword,
      email: this.registerUserShadowForm.value.email
    };

    if (dispatchData.password === dispatchData.confirmPassword) {
      this.dispatchRegisterGuestPinRequest(dispatchData);
    }
  }

  /**
   * @name checkLoadingButton
   * @description
   * check isLoading by id
   * @memberof TeamLoginRequestRegisterUserDialogComponent
   * @param buttonId
   */
  checkLoadingButton(buttonId: number | string): Observable<boolean> {
    return this.isLoadingId$.pipe(
      withLatestFrom(this.isLoading$),
      map(([id, isLoading]) => (buttonId! === id) && isLoading)
    );
  }

  /**
   * @name onFormChange
   * @description
   * handle emit event to update the shadow form
   * @memberof TeamLoginRequestRegisterUserDialogComponent
   * @param form
   */
  onFormChange(form: FormGroup): void {
    this.registerUserShadowForm = form;
  }

  /**
   * @name dispatchRegisterGuestPinRequest
   * @description
   * dispatch the register guest pin request action
   * @memberof TeamLoginRequestRegisterUserDialogComponent
   * @param data
   */
  dispatchRegisterGuestPinRequest(data: IDispatchRegisterGuest): void {
    this._store.dispatch(TeamLoginActions.registerGuestPinRequest({
      registerGuest: {
        isRetry: false,
        email: data.email,
        passwordBase64: data.passwordBase64
      },
      isLoadingId: this.buttonId
    }))
  }
}
