import {AfterContentInit, Component, ContentChild} from '@angular/core';
import {IonicModule, IonInput} from "@ionic/angular";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-shared-show-hide-password',
  templateUrl: './shared-show-hide-password.component.html',
  styleUrls: ['./shared-show-hide-password.component.scss'],
  imports: [
    IonicModule,
    NgIf
  ],
  standalone: true
})
export class SharedShowHidePasswordComponent implements AfterContentInit {

  showPassword: boolean = false;
  @ContentChild(IonInput) input!: IonInput;

  constructor() {
  }

  ngAfterContentInit() {
  }

  toggleShow() {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }

}
