import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-shared-pin-input',
  templateUrl: './shared-pin-input.component.html',
  styleUrls: ['./shared-pin-input.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule
  ],
  standalone: true
})
export class SharedPinInputComponent implements OnInit {
  pinForm!: FormGroup;
  pinDigits: number[];

  @Input() quantity: number;

  @Output() pinFormChanged: EventEmitter<FormGroup>;

  constructor(
    private _fb: FormBuilder,
    private _renderer: Renderer2,
    private _el: ElementRef
  ) {
    this.pinDigits = [];
    this.quantity = 5;
    this.pinFormChanged = new EventEmitter<FormGroup>();
  }

  ngOnInit() {
    this.generatePinDigits();
    this.generateFormControls();
  }

  /**
   * @name generatePinDigits
   * @description
   * generate the ping digits array
   * @memberof
   */
  generatePinDigits(): void {
    this.pinDigits = Array.from({ length: this.quantity }, (_, index) => index + 1);
  }

  /**
   * @name generateFormControls
   * @description
   * generate need it form controls
   * @memberof SharedPinInputComponent
   */
  generateFormControls(): void {
    const controls = this.pinDigits.map(() => [
      '', [Validators.pattern('[0-9]'), Validators.required],
    ]);

    this.pinForm = this._fb.group({
      pin: this._fb.array(controls),
    });
  }

  /**
   * @name onInputChange
   * @description
   * on input change move the focus to the next input and emit the form on the last one
   * @memberof SharedPinInputComponent
   * @param index
   * @param event
   */
  onInputChange(index: number, event: Event): void {
    const inputValue = (event.target as HTMLInputElement).value;

    if (index < this.pinDigits.length - 1 && inputValue !== '') {
      this.setFocus(index + 1);
    } else {
      this.pinFormChanged.emit(this.pinForm);
    }
  }

  /**
   * @name onPaste
   * @description
   * to be able to paste any digit code from email in the whole pin inputs
   * @memberof SharedPinInputComponent
   * @param event
   * @param index
   */
  onPaste(event: ClipboardEvent, index: number): void {
    event.preventDefault();
    const pastedData = event.clipboardData?.getData('text/plain');
    if (pastedData) {
      const pastedDigits = pastedData.split('').slice(0, this.pinDigits.length);

      pastedDigits.forEach((digit, i) => {
        const control = this.pinForm?.get('pin')?.get([index + i]);
        if (control) {
          control.setValue(digit);
          this.onInputChange(index + i, event);
        }
      });
    }
  }

  /**
   * @name onRemove
   * @description
   * on remove be able to go to the previous input and setting the focus
   * @memberof SharedPinInputComponent
   * @param index
   */
  onRemove(index: number) {
    if (index > 0) {
      const currentControl = this.pinForm?.get('pin')?.get([index]);
      const previousControl = this.pinForm?.get('pin')?.get([index - 1]);

      if (currentControl && previousControl) {
        currentControl.setValue('');
        this.setFocus(index - 1);
      }
    }
  }

  /**
   * @name setFocus
   * @description
   * set the focus of the input
   * @memberof SharedPinInputComponent
   * @param index
   */
  setFocus(index: number): void {
    const inputElement = this._el.nativeElement.querySelector(`#pinInput${ index }`);
    if (inputElement) {
      this._renderer.selectRootElement(inputElement).focus();
    }
  }
}
