import {Injectable} from "@angular/core";
import {TeamIssuesApiService} from "@team/pages/issues/services/team-issues-api.service";
import {Observable} from "rxjs";
import {
  SubmitWinterhalterProductOrderIssueDto, WinterhalterProductCatalogDto,
  WinterhalterProductOrderIssueDto,
  WinterhalterProductOrderIssuePreviewDto
} from "@server-models";
import {TeamIssuesActions} from "@team/pages/issues/store/team-issues.actions-type";

@Injectable({
  providedIn: 'root',
})
export class TeamIssuesWinterhalterProductOrderApiService extends TeamIssuesApiService {

  override getIssueTemplatePreviewListById(id: number): Observable<WinterhalterProductOrderIssuePreviewDto> {
    this._store.dispatch(TeamIssuesActions.getWinterhalterProductOrderCatalog({}));
    return super.get<WinterhalterProductOrderIssuePreviewDto>(`/tech/v2/issue/winterhalterproductorder/template/${id}/preview`, undefined);
  }

  override getIssueById(issueId: number): Observable<WinterhalterProductOrderIssueDto> {
    return super.get<WinterhalterProductOrderIssueDto>(`/tech/v2/issue/winterhalterproductorder/${issueId}`);
  }

  override sendIssueTemplatePreviewById(templateId: number, issue: SubmitWinterhalterProductOrderIssueDto): Observable<void> {
    return super.post<void>(`/tech/v2/issue/winterhalterproductorder/template/${templateId}/submitIssue`, undefined, issue);
  }

  getCatalogByResourceId(resourceId: number): Observable<WinterhalterProductCatalogDto> {
    return super.get<WinterhalterProductCatalogDto>(`/tech/v2/issue/winterhalterproductorder/resource/${resourceId}/catalog`, undefined);
  }

}
