import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {IonicModule, NavController, ViewWillEnter} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {filter, Observable, take} from "rxjs";
import {tap} from "rxjs/operators";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {Orders, OrgaFolderItemDto} from "@server-models";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {select, Store} from "@ngrx/store";
import {ActivatedRoute, Router, UrlSegment} from "@angular/router";
import {SharedSearchBarComponent} from "@shared/components/search-bar/shared-search-bar.component";
import {SharedButtonSideMenuComponent} from "@shared/components/button-side-menu/shared-button-side-menu.component";
import {SharedDocumentsSelectors} from "@shared/components/documents/store/shared-document.selectors-type";
import {SharedLoginBaseSelectors} from "@shared/stores/login-base/store/shared-login-base.selector-type";
import {SharedDocumentState} from "@shared/components/documents/store/shared-document.state";
import {SharedDocumentListComponent} from "@shared/components/documents/components/list/shared-document-list.component";
import {SharedDocumentsActions} from "@shared/components/documents/store/shared-document.actions-type";


@Component({
  selector: 'app-shared-document',
  templateUrl: './shared-document.page.html',
  styleUrls: ['./shared-document.page.scss'],
  imports: [
    AsyncPipe,
    IonicModule,
    NgForOf,
    NgIf,
    TranslateModule,
    SharedDocumentListComponent,
    SharedSearchBarComponent,
    SharedButtonSideMenuComponent
  ],
  standalone: true
})
export class SharedDocumentPage implements OnInit, ViewWillEnter {
  // @Input() menuId!: string;

  paginationParams: IPaginationParams;
  isDragRefresh: boolean;
  currentPath: string[];
  currentRoute: UrlSegment[];
  defaultPath: string[];
  isInfinityLoading: boolean;

  documentsData$: Observable<OrgaFolderItemDto[]> | null;
  pagingData$: Observable<IPagination>;
  isLoading$: Observable<boolean>;
  tenantId$: Observable<number | undefined>;

  isDisplaySearchBar: boolean;
  menuId!: string;

  constructor(
    private _store: Store<SharedDocumentState>,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navController: NavController,
  ) {
    this.documentsData$ = this._store.pipe(select(SharedDocumentsSelectors.selectDocumentsPageList));
    this.pagingData$ = this._store.pipe(select(SharedDocumentsSelectors.selectDocumentsPagePagination));
    this.isLoading$ = this._store.pipe(select(SharedDocumentsSelectors.selectDocumentsPageLoading));
    this.tenantId$ = this._store.pipe(select(SharedLoginBaseSelectors.selectTenantId));
    this.isDragRefresh = false;
    this.isInfinityLoading = false;
    this.paginationParams = {
      pageNumber: 1,
      pageSize: 25,
      cols: 'Content',
      sortField: 'Type',
      sort: Number(Orders.Descending),
      sortThenField: 'Name',
      sortThen: Number(Orders.Ascending)
    };
    this.currentPath = [this._activatedRoute.snapshot.queryParamMap.get('path') || ''];
    this.currentRoute = this._activatedRoute.snapshot.url;
    this.defaultPath = [""];
    this.isDisplaySearchBar = false;
  }

  ionViewWillEnter(): void {
    this.loadDocumentsByPagination(true);
  }

  ngOnInit() {
    this._activatedRoute.data.subscribe(({ data }) => {
      this.menuId = data?.menuId;
    });
  }

  /**
   * @name onPathChange
   * @description
   * on path change event launch the load documents
   * @memberof SharedDocumentPage
   * @param paths
   */
  onPathChange(paths: { currentPath: string }): void {
    this.getPath(this.currentPath);
    this.currentPath.push(paths.currentPath);
    this.paginationParams.pageNumber = 1;
    this.loadDocumentsByPagination(true);
  }

  /**
   * @name changeDisplayBar
   * @description
   * change boolean value of display search bar to hide or display
   * @memberof SharedDocumentPage
   */
  changeDisplayBar() {
    this.isDisplaySearchBar = !this.isDisplaySearchBar;
    if (!this.isDisplaySearchBar) {
      this.loadDocumentsByPagination(true);
    }
  }

  /**
   * @name back
   * @description
   * navigate back to the previousPath and relaunch the load documents request
   * @memberof SharedDocumentPage
   */
  async back(): Promise<void> {
    this.currentPath.pop();
    const previousPath = this.getPath(this.currentPath);

    await this._navController.navigateBack([], { queryParams: { path: previousPath } });
    this.paginationParams.pageNumber = 1;
    this.loadDocumentsByPagination(true);
  }

  /**
   * @name loadDocumentsByPagination
   * @description
   * subscribe to tenantId to get the value and dispatch the action to get documents
   * @memberof SharedDocumentPage
   * @param isRefresh
   */
  loadDocumentsByPagination(isRefresh: boolean = false): void {
    this.tenantId$.pipe(
      take(1)
    ).subscribe(data => {
      const isDefaultPath = JSON.stringify(this.currentPath) === JSON.stringify(this.defaultPath);
      if (!isDefaultPath) {
        this.currentPath = this.createPreviousPath(this.currentPath);
      }
      this._store.dispatch(SharedDocumentsActions.getItemsPaginated({
        path: this.getLastPosition(this.currentPath),
        tenantId: data!,
        params: this.paginationParams,
        isRefresh: isRefresh
      }));
    });
  }

  /**
   * @name createPreviousPath
   * @description
   * Use on isRefresh the page the array generated need to have all the paths so create them
   * @memberof SharedDocumentPage
   * @param inputArray
   * @returns {string[]}
   */
  createPreviousPath(inputArray: string[]): string[] {
    const resultArray: string[] = [];
    resultArray.push("");

    for (const item of inputArray) {
      const parts = item.split('/');
      let partial = '';
      for (const part of parts) {
        partial += (partial ? '/' : '') + part;
        if (!resultArray.includes(partial)) {
          resultArray.push(partial);
        }
      }
    }
    return resultArray;
  }

  /**
   * @name getPath
   * @description
   * returns the path string formatted
   * @memberof SharedDocumentPage
   * @param paths
   */
  getPath(paths: string[]): string {
    return paths.join('/');
  }

  /**
   * @name getLastPosition
   * @description
   * return the last item of the array
   * @memberof SharedDocumentPage
   * @param array
   */
  getLastPosition(array: string[]): string {
    const lastPosition = array.length - 1;
    return array[lastPosition];
  }

  /**
   * @name loadMoreDocuments
   * @description
   * load more documents when infinity scroll
   * @memberof SharedDocumentPage
   * @param event
   */
  loadMoreDocuments(event: any): void {
    this.paginationParams.pageNumber++;
    this.isInfinityLoading = true;
    this.loadDocumentsByPagination();
    this.isLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        event.target.complete();
        this.isInfinityLoading = false;
      })
    ).subscribe();
  }

  /**
   * @name handleRefresh
   * @description DocumentsComponent
   * isRefresh the data on drag isRefresh
   * @memberof
   * @param event
   */
  handleRefresh(event: any): void {
    if (this.isDragRefresh) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.isDragRefresh = true;
    if (event) {
      event.target.disabled = true;
    }

    this.paginationParams.pageNumber = 1;
    this.loadDocumentsByPagination(true);

    this.isLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.isDragRefresh = false;
      })
    ).subscribe();
  }

  /**
   * @name onSearch
   * @description
   * trigger the event from base search bar to dispatch a search or isRefresh load documents
   * @memberof SharedDocumentPage
   * @param value
   */
  onSearch(value: string): void {
    if (value) {
      const path = this.getLastPosition(this.currentPath);
      this._store.dispatch(SharedDocumentsActions.getItemsSearched({ toSearch: value, path: path }))
    } else {
      this.loadDocumentsByPagination(true);
    }
  }

  isRoot(): boolean {
    return this.currentPath.length == 1;
  }

  async myPersonal(): Promise<void> {
    await this._router.navigate(['./me'], { relativeTo: this._activatedRoute });
  }
}

