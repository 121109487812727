<ion-list class="ion-no-padding" *ngIf="documentList!.length > 0; else noValues">
  <ng-container *ngFor="let item of documentList">
    <ng-container *ngIf="item.type == 2">
      <ion-item button="button" lines="full" (click)="openFolder(item)">
        <ion-icon name="folder" slot="start"></ion-icon>
        <ion-label [innerText]="item.name"></ion-label>
      </ion-item>
    </ng-container>
    <ng-container *ngIf="item.type == 1">
      <app-shared-attachments [hasIconFolder]="true" [hasHeader]="false"
                              [attachmentsInc]="[item]"></app-shared-attachments>
    </ng-container>
  </ng-container>
</ion-list>
<ng-template #noValues>
  <ion-item>
    <ion-label class="ion-text-center" [innerText]="'PAGES.DOCUMENTS.EMPTY' | translate"></ion-label>
  </ion-item>
</ng-template>

