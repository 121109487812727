<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-title>
      {{ article.title }}
    </ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="back()">
        <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ng-container>
    <app-shared-knowledge-article-detail-start
      [htmlContent]="article.content"
      [attachmentsInc]="article.attachments"
    ></app-shared-knowledge-article-detail-start>
  </ng-container>
</ion-content>


