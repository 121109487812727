import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {teamLoginSelect} from "@team/pages/login/store/team-login.selector";
import {Observable, withLatestFrom} from 'rxjs';
import {map} from "rxjs/operators";
import {EAppType} from "@shared/models/AppType.enum";
import {selectToken} from '@shared/stores/login-base/store/shared-login-base.selector';
import {techLoginSelect} from '@tech/pages/login/store/tech-login.selector';

@Injectable({
  providedIn: 'root'
})
export class TeamAppLoginGuard {

  constructor(
    private store: Store,
    private router: Router
  ) {
  }

  public canActivate(appType: EAppType, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(selectToken).pipe(
      withLatestFrom(this.store.select(techLoginSelect)),
      map(([token, login]) => {
        if (!!token) {
          if (login.currentTenant?.isGuest) {
            return this.router.createUrlTree(['team/welcome']);
          } else {
            return true;
          }
        } else {
          return this.router.createUrlTree(['team/login'], {
            queryParams: {
              redirect: `${ state.url }`
            }
          });
        }
      })
    )
  }

}
