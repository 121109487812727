import {createReducer, on} from "@ngrx/store";
import {TeamInfoState} from "@team/pages/infos/store/team-info.state";
import {TeamInfoActions} from './team-info.actions-type';
import {CoreEnvironmentService} from "@core/services/environment/core-environment.service";

function getInitialInfosState(): TeamInfoState {
  const env = CoreEnvironmentService.getEnvironment();
  return {
    page: {},
    items: [],
    paging: {
      totalItems: 0,
      pageNumber: 0,
      pageSize: env?.apiUrl?.pageDefaultSize || 1,
      totalPages: 1
    },
    isLoading: true,
    isLoadingId: NaN
  }
}

export const teamInfoStore = createReducer(
  getInitialInfosState(),
  on(TeamInfoActions.postItemsPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TeamInfoActions.postItemsPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsPaginatedSuccess, (state, { data }) => ({
    ...state,
    // Important! include the current state.items and the data.items to create a new array with the
    // existing ones and the new one that is requested
    items: [...state.items, ...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsPaginatedRefresh, (state, { data }) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsMyRead, (state, data) => ({
    ...state,
    isLoading: false,
    isLoadingId: data.id
  })),
  on(TeamInfoActions.postItemsMyReadSuccess, (state, { updatedItems, data }) => ({
      ...state,
      items: updatedItems,
      isLoadingId: undefined,
      isLoading: false
    })
  ),
  on(TeamInfoActions.postItemsMyReadFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsMyReadFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsSearchedPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TeamInfoActions.postItemsSearchedPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsSearchedPaginatedSuccess, (state, { data }) => ({
    ...state,
    // Important! include the current state.items and the data.items to create a new array with the
    // existing ones and the new one that is requested
    items: [...state.items, ...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsSearchedPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamInfoActions.postItemsSearchedPaginatedRefresh, (state, { data }) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
);
