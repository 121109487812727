import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TeamInfoState} from "@team/pages/infos/store/team-info.state";

export const teamInfoStoreSelect = createFeatureSelector<TeamInfoState>('teamInfos');

export const selectInfoPage = createSelector(
  teamInfoStoreSelect,
  (state: TeamInfoState) => state.page
);

export const selectInfoPageList = createSelector(
  teamInfoStoreSelect,
  (state: TeamInfoState) => state.items
);

export const selectInfoPagePagination = createSelector(
  teamInfoStoreSelect,
  (state: TeamInfoState) => state.paging
);

export const selectInfoPageLoading = createSelector(
  teamInfoStoreSelect,
  (state: TeamInfoState) => state.isLoading
);

export const selectInfoPageLoadingId = createSelector(
  teamInfoStoreSelect,
  (state: TeamInfoState) => state.isLoadingId
);
