import {createReducer, on} from "@ngrx/store";
import {SharedDocumentState} from "@shared/components/documents/store/shared-document.state";
import {SharedDocumentsActions} from "@shared/components/documents/store/shared-document.actions-type";
import {CoreEnvironmentService} from "@core/services/environment/core-environment.service";

function getInitialSharedDocumentsState(): SharedDocumentState {
  const env = CoreEnvironmentService.getEnvironment();

  return {
    page: {},
    items: [],
    paging: {
      totalItems: 0,
      pageNumber: 0,
      pageSize: env?.apiUrl?.pageDefaultSize,
      totalPages: 1
    },
    isLoading: true,
    isLoadingId: NaN,
    personal: {
      page: {},
      items: [],
      paging: {
        totalItems: 0,
        pageNumber: 0,
        pageSize: env?.apiUrl?.pageDefaultSize,
        totalPages: 1
      },
      isLoading: true,
      isLoadingId: NaN
    }
  };
}

export const sharedDocumentStore = createReducer(
  getInitialSharedDocumentsState(),
  on(SharedDocumentsActions.getItemsPaginated, (state) => ({
    ...state,
    isLoading: true
  })),
  on(SharedDocumentsActions.getItemsPaginatedCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedDocumentsActions.getItemsPaginatedSuccess, (state, { data }) =>
    ({
      ...state,
      // Important! include the current state.items and the data.items to create a new array with the
      // existing ones and the new one that is requested
      items: [...state.items, ...data.items],
      paging: data.paging,
      isLoading: false
    })
  ),
  on(SharedDocumentsActions.getItemsPaginatedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedDocumentsActions.getItemsPaginatedRefresh, (state, { data }) => ({
    ...state,
    items: [...data.items],
    paging: data.paging,
    isLoading: false
  })),
  on(SharedDocumentsActions.getItemsSearched, (state) => ({
    ...state,
    paging: {
      totalItems: getInitialSharedDocumentsState().paging.totalItems,
      pageNumber: getInitialSharedDocumentsState().paging.pageNumber,
      pageSize: getInitialSharedDocumentsState().paging.pageSize,
      totalPages: getInitialSharedDocumentsState().paging.totalPages,
    },
    isLoading: true
  })),
  on(SharedDocumentsActions.getItemsSearchedSuccess, (state, { data }) =>
    ({
      ...state,
      items: [...data.items],
      paging: data.paging,
      isLoading: false
    })
  ),
  on(SharedDocumentsActions.getItemsSearchedFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(SharedDocumentsActions.getItemsPersonalPaginated, (state) => ({
    ...state,
    personal: {
      ...state.personal,
      isLoading: true
    }
  })),
  on(SharedDocumentsActions.getItemsPersonalPaginatedCancel, (state) => ({
    ...state,
    personal: {
      ...state.personal,
      isLoading: false
    }
  })),
  on(SharedDocumentsActions.getItemsPersonalPaginatedSuccess, (state, { data }) =>
    ({
      ...state,
      personal: {
        ...state.personal,
        // Important! include the current state.items and the data.items to create a new array with the
        // existing ones and the new one that is requested
        items: [...state.items, ...data.items],
        paging: data.paging,
        isLoading: false
      }
    })
  ),
  on(SharedDocumentsActions.getItemsPersonalPaginatedFail, (state) => ({
    ...state,
    personal: {
      ...state.personal,
      isLoading: false
    }
  })),
  on(SharedDocumentsActions.getItemsPersonalPaginatedRefresh, (state, { data }) => ({
    ...state,
    personal: {
      ...state.personal,
      items: [...data.items],
      paging: data.paging,
      isLoading: false
    }
  })),
);
