import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {filter, Observable} from "rxjs";
import {KnowledgePathListDto, Orders} from "@server-models";
import {select, Store} from "@ngrx/store";
import {selectKnowledgePathList, selectKnowledgePathLoading} from "@team/pages/knowledge/store/knowledge.selectors";
import {KnowledgeState} from "@team/pages/knowledge/store/knowledge.state";
import {RouterLink} from "@angular/router";
import {tap} from "rxjs/operators";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {TeamKnowledgeCardComponent} from "@team/pages/knowledge/components/card/team-knowledge-card.component";
import {SharedSearchBarComponent} from "@shared/components/search-bar/shared-search-bar.component";
import {KnowledgeActions} from "@team/pages/knowledge/store/knowledge.actions-type";
import {SharedButtonSideMenuComponent} from "@shared/components/button-side-menu/shared-button-side-menu.component";

@Component({
  selector: 'app-team-knowledge',
  templateUrl: './team-knowledge.page.html',
  styleUrls: ['./team-knowledge.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, RouterLink, TeamKnowledgeCardComponent, SharedSearchBarComponent, SharedButtonSideMenuComponent]
})
export class TeamKnowledgePage implements OnInit {

  knowledgePathData$: Observable<KnowledgePathListDto[]>;
  knowledgePathIsLoading$: Observable<boolean>;
  paginationParams: IPaginationParams;
  isInfinityLoading: boolean;

  isDragRefresh: boolean;
  isDisplaySearchBar: boolean;

  constructor(private _store: Store<KnowledgeState>) {
    this.knowledgePathData$ = this._store.pipe(select(selectKnowledgePathList));
    this.knowledgePathIsLoading$ = this._store.pipe(select(selectKnowledgePathLoading));
    this.isDragRefresh = false;
    this.isInfinityLoading = false;
    this.isDisplaySearchBar = false;
    this.paginationParams = {
      pageNumber: 1,
      pageSize: 25,
      cols: 'Content',
      sort: Number(Orders.Descending)
    };
  }

  ngOnInit() {
    this.loadKnowledgePathList();
  }

  /**
   * @name loadKnowledgePathList
   * @description
   * launch the action to load knowledge path list by a get params
   * @memberof TeamKnowledgePage
   * @param isRefresh
   */
  loadKnowledgePathList(isRefresh: boolean = false): void {
    this._store.dispatch((KnowledgeActions.getPathItemsPaged({
      params: this.paginationParams,
      isRefresh: isRefresh
    })));
  }

  /**
   * @name loadMoreKnowledgePath
   * @description
   * request the launch once the infinity scroll bottom is reached
   * @memberof TeamKnowledgePage
   * @param event
   */
  loadMoreKnowledgePath(event: any): void {
    this.paginationParams.pageNumber++;
    this.isInfinityLoading = true;
    this.loadKnowledgePathList();
    this.knowledgePathIsLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        event.target.complete();
        this.isInfinityLoading = false;
      })
    ).subscribe();
  }

  /**
   * @name changeDisplayBar
   * @description
   * change boolean value of display search bar to hide or display
   * @memberof TeamKnowledgePage
   */
  changeDisplayBar() {
    this.isDisplaySearchBar = !this.isDisplaySearchBar;
    if (!this.isDisplaySearchBar) {
      this.loadKnowledgePathList(true);
    }
  }

  /**
   * @name onSearch
   * @description
   * trigger the event from base search bar to dispatch a search or isRefresh load documents
   * @memberof TeamKnowledgePage
   * @param value
   */
  onSearch(value: string): void {
    if (value) {
      this._store.dispatch(KnowledgeActions.getItemsSearched({ toSearch: value }))
    } else {
      this.loadKnowledgePathList(true);
    }
  }

  /**
   * @name handleRefresh
   * @description
   * Use to reset the pageNumber param, and call with isRefresh flag loadKnowledgePath
   * @memberof TeamKnowledgePage
   * @param event
   */
  handleRefresh(event: any): void {
    if (this.isDragRefresh) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.isDragRefresh = true;
    if (event) {
      event.target.disabled = true;
    }

    this.paginationParams.pageNumber = 1;
    this.loadKnowledgePathList(true);

    this.knowledgePathIsLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.isDragRefresh = false;
      })
    ).subscribe();
  }

}
