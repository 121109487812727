import {AbstractControl, ValidatorFn, ValidationErrors} from '@angular/forms';

/**
 * @name sharedPasswordMatchValidator
 * @description
 * Custom validation to check if the two confirmPassword match newPassword value
 * @param passwordField
 * @param confirmPasswordField
 */
export const sharedPasswordMatchValidator = (passwordField: string, confirmPasswordField: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const passwordControl = control.get(passwordField);
    const confirmPasswordControl = control.get(confirmPasswordField);

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    return passwordControl.value !== confirmPasswordControl.value ?
      { passwordMismatch: true } : null;
  };
};
