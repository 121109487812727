import {createAction, props} from "@ngrx/store";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {OrgaFolderItemDto} from "@server-models";
import {
  IDocumentsRequestPagination
} from "@shared/components/documents/interfaces/document-request-pagination.interface";

export const getItemsPaginated = createAction(
  '[Documents-Shared] Get Paginated',
  props<IDocumentsRequestPagination>()
);

export const getItemsPaginatedCancel = createAction(
  '[Documents-Shared] Get Paginated Cancel'
);

export const getItemsPaginatedRefresh = createAction(
  '[Documents-Shared] Get Paginated Refresh',
  props<{ data: IOrgaResponse<OrgaFolderItemDto[]> }>()
);

export const getItemsPaginatedSuccess = createAction(
  '[Documents-Shared] Get Paginated Success',
  props<{ data: IOrgaResponse<OrgaFolderItemDto[]> }>()
);
export const getItemsPaginatedFail = createAction(
  '[Documents-Shared] Get Paginated Fail',
  props<{ error: any }>()
);

export const getItemsSearched = createAction(
  '[Documents-Shared] Get Searched',
  props<{ toSearch: string, path: string }>()
);

export const getItemsSearchedSuccess = createAction(
  '[Documents-Shared] Get Searched Success',
  props<{ data: IOrgaResponse<OrgaFolderItemDto[]> }>()
);

export const getItemsSearchedFail = createAction(
  '[Documents-Shared] Get Searched Fail',
  props<{ error: any }>()
);

export const getItemsPersonalPaginated = createAction(
  '[Documents-Shared] Get Personal Paginated',
  props<IDocumentsRequestPagination>()
);

export const getItemsPersonalPaginatedCancel = createAction(
  '[Documents-Shared] Get Personal Paginated Cancel'
);

export const getItemsPersonalPaginatedRefresh = createAction(
  '[Documents-Shared] Get Personal Paginated Refresh',
  props<{ data: IOrgaResponse<OrgaFolderItemDto[]> }>()
);

export const getItemsPersonalPaginatedSuccess = createAction(
  '[Documents-Shared] Get Personal Paginated Success',
  props<{ data: IOrgaResponse<OrgaFolderItemDto[]> }>()
);
export const getItemsPersonalPaginatedFail = createAction(
  '[Documents-Shared] Get Personal Paginated Fail',
  props<{ error: any }>()
);
