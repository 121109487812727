import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-shared-base-search-bar',
  templateUrl: './shared-search-bar.component.html',
  styleUrls: ['./shared-search-bar.component.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule]

})
export class SharedSearchBarComponent {

  @Output() valueToSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() clear: EventEmitter<void> = new EventEmitter<void>();

  /**
   * @name onSearch
   * @description
   * emit the value of search bar on input with debounce of 500
   * @memberof SharedSearchBarComponent
   * @param event
   */
  onSearch(event: CustomEvent): void {
    const searchValue = event?.detail?.value ?? '';
    this.valueToSearch.emit(searchValue);
  }

  onClear(): void {
    this.clear.emit();
  }

}
