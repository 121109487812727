<ion-header>
  <ion-toolbar color="primary">
    <ion-title [innerText]="'LOGIN.REGISTER_GUEST.DIALOGS.PIN_REGISTER.HEADER_TITLE' | translate">
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="exit()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ 'LOGIN.REGISTER_GUEST.DIALOGS.PIN_REGISTER.SALUTE' | translate }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content class="card-padding">
      <span>{{ 'LOGIN.REGISTER_GUEST.DIALOGS.PIN_REGISTER.DESCRIPTION' | translate }}</span>&nbsp;
      <b>{{ email }}</b>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <ion-card-header>
      <ion-card-title>
        {{ 'LOGIN.REGISTER_GUEST.DIALOGS.PIN_REGISTER.PIN.TITLE' | translate }}
      </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <app-shared-pin-input (pinFormChanged)="onPinFormChanged($event)"></app-shared-pin-input>
      <div class="note-wrapper">
        <ion-note>
          {{ 'LOGIN.REGISTER_GUEST.DIALOGS.PIN_REGISTER.PIN.NOTE' | translate }}
        </ion-note>
        <ion-note>
          <a *ngIf="!(checkLoadingButton(this.email) | async)" (click)="requestPinAgain()">
            {{ 'LOGIN.REGISTER_GUEST.DIALOGS.PIN_REGISTER.BUTTONS.TRY_AGAIN' | translate }}
          </a>
          <a *ngIf="(checkLoadingButton(this.email) | async)">
            <ion-spinner></ion-spinner>
          </a>
        </ion-note>
      </div>
    </ion-card-content>
  </ion-card>
  <ion-card class="ion-no-padding card-button">
    <ng-container *ngIf="!(checkLoadingButton(currentPin)| async)">
      <ion-button [disabled]="this.pinForm.invalid || isSubmitDisabled"
                  class="submit" expand="block" (click)="submit()">
        {{ 'LOGIN.REGISTER_GUEST.DIALOGS.PIN_REGISTER.BUTTONS.SUBMIT' | translate }}
      </ion-button>
    </ng-container>
    <ng-container *ngIf="(checkLoadingButton(currentPin) | async)">
      <ion-button [disabled]="true"
                  class="submit" expand="block">
        <ion-spinner></ion-spinner>
      </ion-button>
    </ng-container>
  </ion-card>
</ion-content>

