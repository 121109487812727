import {createReducer, on} from "@ngrx/store";
import {TeamLoginState} from "./team-login.state";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {EAppType} from "@shared/models/AppType.enum";

export const initialState: TeamLoginState = <TeamLoginState>{ currentApp: EAppType.Team };

export const teamLoginStore = createReducer(
  initialState,
  on(TeamLoginActions.loadStorage, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TeamLoginActions.loadStorageSuccess, (state, {
      tenantDisplayName,
      isGuest,
      tenantSettings,
      tenantId,
      tenantLanguage,
      tokenDto,
      app,
      isLogging
    }) => ({
      ...state,
      currentTenant: {
        tenantDisplayName,
        isGuest,
        tenantSettings,
        tenantId,
        tenantLanguage,
      },
      app: tokenDto,
      currentApp: app,
      isLogged: !!tokenDto,
      isLogging
    })
  ),
  on(TeamLoginActions.loadStorageFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.initSuccess, (state, { token, app, currentTenant, isLogging }) => ({
    ...state,
    currentApp: app,
    app: token,
    currentTenant,
    isLoading: true,
    isLogging: isLogging!
  })),
  on(TeamLoginActions.initFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.byPassword, (state, { login, password, passwordBase64 }) => ({
    ...state,
    currentLogin: {
      email: login,
      passwordBase64: password ? btoa(password!) : passwordBase64!
    },
    isLoading: true
  })),
  on(TeamLoginActions.byPasswordSuccess, (state, { token }) => ({
      ...state,
      app: token,
      isLogging: true,
      isLoading: true
    })
  ),
  on(TeamLoginActions.byPasswordFail, (state) => ({
    ...state,
    isLogging: false,
    isLoading: false
  })),
  on(TeamLoginActions.loginRefresh, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TeamLoginActions.loginRefreshSuccess, (state, { tokenDto }) => ({
    ...state,
    app: tokenDto,
    isLoading: true
  })),
  on(TeamLoginActions.loginRefreshFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.changePassword, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TeamLoginActions.changePasswordSuccess, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.changePasswordFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.successMultiTenant, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TeamLoginActions.successUniqueTenant, (state, { currentTenant, isLogging }) => ({
    ...state,
    currentTenant,
    isLoading: true,
    isLogging
  })),
  on(TeamLoginActions.cancelSelectedTenant, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.successSelectedTenant, (state, { tenantId }) => ({
    ...state,
    currentTenant: {
      tenantId
    },
    isLoading: true
  })),
  on(TeamLoginActions.fetchTenantSettings, (state, { tenantId }) => ({
    ...state,
    currentTenant: {
      tenantId
    },
    isLoading: true
  })),
  on(TeamLoginActions.fetchTenantSettingsCancel, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.fetchTenantSettingsSuccess, (state, { currentTenant }) => ({
      ...state,
      currentTenant,
      isLoading: true
    })
  ),
  on(TeamLoginActions.fetchTenantSettingsFail, (state, {}) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.isLoggingNavigationDone, (state) => ({
      ...state,
      isLogging: false,
      isLoading: false
    })
  ),
  on(TeamLoginActions.isLoggingNavigationCanceled, (state) => ({
      ...state,
      isLogging: false,
      isLoading: true
    })
  ),
  on(TeamLoginActions.initRegistrationGuest, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId
  })),
  on(TeamLoginActions.initRegistrationGuestDialogClose, (state) => ({
    ...state,
    isLoading: false,
    isLoadingId: ""
  })),
  on(TeamLoginActions.initRegistrationGuestDialogClose, (state) => ({
    ...state,
    isLoading: false,
    isLoadingId: ""
  })),
  on(TeamLoginActions.invitationCodeGuestCheck, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId: isLoadingId!
  })),
  on(TeamLoginActions.initInvitationCodeGuest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TeamLoginActions.invitationCodeGuestCheckDialogOpen, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TeamLoginActions.invitationCodeGuestCheckDialogClose, (state) => ({
    ...state,
    isLoading: false,
    isLoadingId: ""
  })),
  on(TeamLoginActions.invitationCodeGuestCheckRevoked, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(TeamLoginActions.invitationCodeGuestCheckSuccess, (state) => ({
    ...state,
    isLoading: true
  })),
  on(TeamLoginActions.invitationCodeGuestCheckFail, (state) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.registerGuestPinRequest, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId: isLoadingId!
  })),
  on(TeamLoginActions.registerGuestPinRequestSuccess, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(TeamLoginActions.registerGuestPinRequestFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(TeamLoginActions.registerGuestAsUser, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId: isLoadingId!
  })),
  on(TeamLoginActions.registerGuestAsUserSuccess, (state) => ({
    ...state,
    isLoading: true,
    isLogging: true
  })),
  on(TeamLoginActions.registerGuestAsUserFail, (state) => ({
    ...state,
    isLoading: false,
  })),

  on(TeamLoginActions.invitationCodeGuestAccept, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId: isLoadingId!
  })),
  on(TeamLoginActions.invitationCodeGuestAcceptCancel, (state) => ({
    ...state,
    isLoading: false,
    isLoadingId: ""
  })),
  on(TeamLoginActions.invitationCodeGuestAcceptSuccess, (state, { tenantId }) => ({
    ...state,
    currentTenant: {
      tenantId
    },
    isLoading: true,
  })),
  on(TeamLoginActions.invitationCodeGuestAcceptFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(TeamLoginActions.resetPassword, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId
  })),
  on(TeamLoginActions.resetPasswordSuccess, (state, {}) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.resetPasswordFail, (state, {}) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.resetRequestPassword, (state, { isLoadingId }) => ({
    ...state,
    isLoading: true,
    isLoadingId
  })),
  on(TeamLoginActions.resetRequestPasswordSuccess, (state, {}) => ({
    ...state,
    isLoading: false
  })),
  on(TeamLoginActions.resetRequestPasswordFail, (state, {}) => ({
    ...state,
    isLoading: false
  }))
);

