<ng-container *ngIf="sharedDocumentsStore$ | async as documentsModel">
  <ion-header [translucent]="true">
    <ion-toolbar color="primary">
      <ion-buttons>
        <ion-button (click)="back()">
          <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
        </ion-button>
        <ng-container>
          <app-shared-button-side-menu [menuId]="menuId" [isShowMenu]="false"
                                       [title]="'PAGES.DOCUMENTS.PAGE_NAME_PERSONAL' | translate"></app-shared-button-side-menu>
        </ng-container>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ng-container *ngIf="!documentsModel.personal.isLoading else loading">
      <ion-card class="card-container">
        <app-shared-document-list
          [documentList]="documentsModel.personal.items"></app-shared-document-list>
      </ion-card>
    </ng-container>
    <ng-template #loading>
      <ion-spinner></ion-spinner>
    </ng-template>
  </ion-content>
</ng-container>

