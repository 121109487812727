import {Injectable} from "@angular/core";
import {TeamIssueApiService} from "@team/pages/issues/services/team-issue-api.service";
import {Observable} from "rxjs";
import {SubmitTicketIssueDto, TicketIssueDto, TicketIssuePreviewDto} from "@server-models";

@Injectable({
  providedIn: 'root',
})
export class TeamIssueTicketApiService extends TeamIssueApiService {

  override getIssueTemplatePreviewListById(id: number): Observable<TicketIssuePreviewDto> {
    return super.get<TicketIssuePreviewDto>(`/tech/v2/issue/ticket/template/${ id }/preview`, undefined);
  }

  override getIssueById(issueId: number): Observable<TicketIssueDto> {
    return super.get<TicketIssueDto>(`/tech/v2/issue/ticket/${ issueId }`);
  }

  override sendIssueTemplatePreviewById(templateId: number, issue: SubmitTicketIssueDto): Observable<void> {
    return super.post<void>(`/tech/v2/issue/ticket/template/${ templateId }/submitIssue`, undefined, issue)
  }
}
