import {Pipe, PipeTransform} from '@angular/core';
import {TenantInfoDto} from '@server-models';

@Pipe({
  name: 'sharedTenantFilter',
  standalone: true
})
export class SharedTenantFilterPipe implements PipeTransform {

  public transform(value: TenantInfoDto[], searchTerm: string): TenantInfoDto[] {
    return searchTerm?.length ? value.filter(tenant => tenant.name?.toLowerCase().includes(searchTerm.toLowerCase())) : value;
  }
}
