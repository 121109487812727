import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {TranslateModule} from "@ngx-translate/core";
import {OrgaFolderItemDto} from "@server-models";
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-shared-document-list',
  templateUrl: './shared-document-list.component.html',
  styleUrls: ['./shared-document-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    SharedAttachmentsComponent
  ]
})
export class SharedDocumentListComponent {

  @Input() documentList: OrgaFolderItemDto[] | null;
  @Input() document!: OrgaFolderItemDto;
  @Output() pathChange: EventEmitter<{ currentPath: string }> = new EventEmitter<{ currentPath: string }>();
  @Output() onHasMoreDoc: EventEmitter<boolean> = new EventEmitter<boolean>();
  previousPath: string;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.documentList = [];
    this.previousPath = "";
  }

  /**
   * @name openFolder
   * @description
   * open a folder if type folder and navigate to the item.path on click
   * @memberof SharedDocumentListComponent
   * @param item
   */
  async openFolder(item: OrgaFolderItemDto): Promise<void> {
    if (item.type == 2) {
      this.pathChange.emit({ currentPath: item.path! });
      this.previousPath = item.path!;

      await this._router.navigate(['./'], { relativeTo: this._activatedRoute, queryParams: { path: item.path } });
    }
  }

}
