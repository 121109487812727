import {Injectable} from "@angular/core";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {Observable} from "rxjs";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {Orders, OrgaFolderItemDto} from "@server-models";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class SharedDocumentApiService extends CoreHttpClientApiBaseService {

  /**
   * @name getDocumentsItems
   * @description
   * make a request throw the http client base api to get the documents items
   * @memberof SharedDocumentApiService
   * @param tenantId
   * @param path
   * @param params
   */
  getDocumentsItems(tenantId: number, path: string,
                    params: IPaginationParams): Observable<IOrgaResponse<OrgaFolderItemDto[]>> {
    const options = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        SortField: params.sortField!,
        Sort: params.sort!.toString()!,
        SortThenField: params.sortThenField!,
        SortThen: params.sortThen!.toString()!,
        path
      }
    }
    return super.get<IOrgaResponse<OrgaFolderItemDto[]>>(`/orga/document/tenant/${ tenantId }`,
      undefined, options)
  }

  /**
   * @name getDocumentsBySearch
   * @description
   * make a request to search documents by search text
   * @memberof SharedDocumentApiService
   * @param searchText
   * @param path
   * @return {Observable<IOrgaResponse<OrgaFolderItemDto[]>>}
   */
  getDocumentsBySearch(searchText: string, path: string): Observable<IOrgaResponse<OrgaFolderItemDto[]>> {
    const options = {
      params: {
        SortField: "Type",
        Sort: Orders.Descending.toString(),
        SortThenField: "Name",
        SortThen: Orders.Ascending.toString(),
        searchText,
        path
      }
    }
    return super.get<IOrgaResponse<OrgaFolderItemDto[]>>(`/orga/document/search`, undefined, options);
  }

  /**
   * @name getDocumentsPersonalItems
   * @description
   * make a request throw the http client base api to get personal documents items
   * @memberof SharedDocumentApiService
   * @param tenantId
   * @param path
   * @param params
   */
  getDocumentsPersonalItems(tenantId: number, path: string,
                            params: IPaginationParams): Observable<IOrgaResponse<OrgaFolderItemDto[]>> {
    const options = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        SortField: params.sortField!,
        Sort: params.sort!.toString()!,
        SortThenField: params.sortThenField!,
        SortThen: params.sortThen!.toString()!,
        path
      }
    }
    return super.get<IOrgaResponse<OrgaFolderItemDto[]>>(`/orga/document/tenant/${ tenantId }/me`,
      undefined, options)
  }
}
