import {Injectable} from "@angular/core";
import {CoreErrorHandlerBaseService} from "@core/services/error-handler-base/core-error-handler-base.service";
import {IAlertControllerCreate} from "@shared/interfaces/alert-controller-create.interface";

@Injectable({
  providedIn: 'root'
})
export class TeamErrorHandlerGuestLoginTeamCodeService extends CoreErrorHandlerBaseService {

  override _formatAlertFor404(status: number): IAlertControllerCreate {
    this._errorText = this._translateService.instant("GENERAL.ENDPOINTS.ONE.ERRORS.ERROR");
    return {
      header: `${ this._errorText }: ${ status.toString() }`,
      subHeader: '',
      message: this._translateService.instant("TEAM.WELCOME.DIALOGS.TEAM_CODE.ERRORS.404"),
      buttons: [
        { text: 'OK' }
      ]
    };
  }
}
