import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";
import {SharedCustomPropertyFormInputTextComponent} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";
import {SharedShowHidePasswordComponent} from "@shared/components/show-hide-password/shared-show-hide-password.component";
import {
  SharedCustomPropertyFormInputEmailComponent
} from "@shared/components/custom-property-form/components/input/components/email/shared-custom-property-form-input-email.component";
import {
  sharedPasswordMatchValidator
} from "@shared/custom-validators/shared-custom-validator-passwords-match.validator";

@Component({
  selector: 'app-team-login-register-user-form',
  templateUrl: './team-login-register-user-form.component.html',
  styleUrls: ['./team-login-register-user-form.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedSafePipe,
    SharedUserHtmlComponent,
    SharedCustomPropertyFormInputEmailComponent,
    SharedCustomPropertyFormInputTextComponent,
    SharedShowHidePasswordComponent
  ],
  standalone: true
})
export class TeamLoginRegisterUserFormComponent implements OnInit {

  @Input() existingEmail: string;
  registerUserForm: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;
  @Output() submitChange: EventEmitter<FormGroup>;

  constructor(
    private _modalControllerService: SharedModalControllerService,
    public formBuilder: FormBuilder,
  ) {
    this.existingEmail = "";
    this.registerUserForm = new FormGroup({});
    this.formChange = new EventEmitter<FormGroup>();
    this.submitChange = new EventEmitter<FormGroup>();
  }

  getControl(controlName: string) {
    return this.registerUserForm.get(controlName) as FormControl;
  }

  ngOnInit() {
    this.initializeRegisterPasswordForm();
    this.registerUserForm.valueChanges.subscribe(() => {
      this.formChange.emit(this.registerUserForm);
    });
  }

  /**
   * @name initializeRegisterPasswordForm
   * @description
   * init register password form
   * @memberof TeamLoginRegisterUserFormComponent
   */
  initializeRegisterPasswordForm() {
    this.registerUserForm = this.formBuilder.group({
      email: [this.existingEmail ? this.existingEmail : "", Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    }, { validators: [sharedPasswordMatchValidator('password', 'confirmPassword')] });
  }

  /**
   * @name exit
   * @description
   * close the modal
   * @memberof TeamLoginRegisterUserFormComponent
   */
  exit(): void {
    this._modalControllerService.closeModal();
  }

  /**
   * @name submitForm
   * @description
   * emit the changes
   * @memberof TeamLoginRegisterUserFormComponent
   */
  submitForm() {
    this.submitChange.emit(this.registerUserForm);
  }

  /**
   * @name formatPin
   * @description
   * format the pin to a full number
   * @memberof TeamLoginRegisterUserFormComponent
   * @param pinArray
   * @returns {number}
   */
  formatPin(pinArray: number[]): number {
    return parseInt(pinArray.join(''));
  }

  /**
   * @name isRequired
   * @description
   * check if a control is required
   * @memberof TeamLoginRegisterUserFormComponent
   * @param controlName
   */
  isRequired(controlName: string): boolean {
    const control = this.registerUserForm.get(controlName);
    if (control) {
      const validator = control.validator ? control.validator({} as any) : null;
      return !!(validator && validator['required']);
    }
    return false;
  }
}
