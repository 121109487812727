import {createAction, props} from "@ngrx/store";
import {MeDto, RegisterUserDto, ResetPasswordDto, TokenDto} from "@server-models";
import {EAppType} from "@shared/models/AppType.enum";
import {IRegisterGuestAction} from "@tech/pages/login/interfaces/register-guest-action.interface";
import {ITechTenantInfoSettings} from "@tech/pages/login/interfaces/tech-tenant-info-settings.interface";

export const init = createAction(
  '[Login-Tech] Init'
);

export const initSuccess = createAction(
  '[Login-Tech] Init Success',
  props<{ app: EAppType, token?: TokenDto, currentTenant?: ITechTenantInfoSettings, isLogging?: boolean }>()
);

export const initFail = createAction(
  '[Login-Tech] Init Fail',
  props<{ error: any }>()
);

export const loadStorage = createAction(
  '[Login-Tech] Load Storage Start'
);

export const loadStorageSuccess = createAction(
  '[Login-Tech] Load Storage Success',
  props<{
    tenantSettings: any,
    tenantId: number,
    tenantLanguage: string,
    isGuest: boolean,
    tenantDisplayName: string,
    tokenDto: TokenDto,
    app: EAppType,
    isLogging: boolean
  }>()
);

export const loadStorageFail = createAction(
  '[Login-Tech] Load Storage Fail',
  props<{ error: any }>()
);

export const byPassword = createAction(
  '[Login-Tech] By Password',
  props<{ login: string, password: string | undefined, passwordBase64?: string }>()
);

export const byPasswordSuccess = createAction(
  '[Login-Tech] By Password Success',
  props<{ token: TokenDto }>()
);

export const byPasswordFail = createAction(
  '[Login-Tech] By Password Fail',
  props<{ error: any }>()
);

export const initRegistrationGuest = createAction(
  '[Login-Tech] Registration Guest Start',
  props<{ isLoadingId: number | string, currentEmail: string | undefined }>()
);

export const initRegistrationGuestDialogOpen = createAction(
  '[Login-Tech] Registration Guest Dialog Open',
  props<{ currentEmail: string | undefined }>()
);

export const initRegistrationGuestDialogClose = createAction(
  '[Login-Tech] Registration Guest Dialog Close'
);

export const initRegistrationGuestSuccess = createAction(
  '[Login-Tech] Registration Guest Success'
);

export const initRegistrationGuestFail = createAction(
  '[Login-Tech] Registration Guest Fail',
  props<{ error: any }>()
);

export const registerGuestPinRequest = createAction(
  '[Login-Tech] Guest Pin Request Start',
  props<{ registerGuest: IRegisterGuestAction, isLoadingId?: number | string }>()
);

export const registerGuestPinRequestSuccess = createAction(
  '[Login-Tech] Guest Pin Request Success',
  props<IRegisterGuestAction>()
);

export const registerGuestPinRequestFail = createAction(
  '[Login-Tech] Guest Pin Request Fail',
  props<{ error: any }>()
);

export const registerGuestPinDialog = createAction(
  '[Login-Tech] Guest Pin Dialog Start',
  props<any>()
);

export const registerGuestPinDialogCancel = createAction(
  '[Login-Tech] Guest Pin Dialog Cancel'
);

export const registerGuestAsUser = createAction(
  '[Login-Tech] Guest As User Start',
  props<{ data: RegisterUserDto, isLoadingId: number | string, isRetry?: boolean }>()
);

export const registerGuestAsUserSuccess = createAction(
  '[Login-Tech] Guest As User Success',
  props<{
    email: string;
    passwordBase64: string;
    isRetry: boolean;
  }>()
);

export const registerGuestAsUserFail = createAction(
  '[Login-Tech] Guest As User Fail',
  props<{ error: any }>()
);

export const initLogout = createAction(
  '[Login-Tech] Logout'
);

export const changePassword = createAction(
  '[Login-Tech] Change Password start',
  props<{ email: string, oldPasswordBase64: string, newPasswordBase64: string }>()
);

export const changePasswordSuccess = createAction(
  '[Login-Tech] Change Password success'
);

export const changePasswordFail = createAction(
  '[Login-Tech] Change Password fail',
  props<{ error: any }>()
);

export const resetPassword = createAction(
  '[Login-Tech] Reset Password start',
  props<{ resetData: ResetPasswordDto, isLoadingId: number | string }>()
);

export const resetPasswordSuccess = createAction(
  '[Login-Tech] Reset Password success',
  props<{ email: string, password: string }>()
);

export const resetPasswordFail = createAction(
  '[Login-Tech] Reset Password fail',
  props<{ error: any }>()
);

export const resetRequestPassword = createAction(
  '[Login-Tech] Reset Request Password start',
  props<{ email: string, isLoadingId: number | string }>()
);

export const resetRequestPasswordSuccess = createAction(
  '[Login-Tech] Reset Request Password success'
);

export const resetRequestPasswordFail = createAction(
  '[Login-Tech] Reset Request Password fail',
  props<{ error: any }>()
);

export const successUniqueTenant = createAction(
  '[Login-Tech] Unique tenant Success',
  props<{ app: EAppType, token?: TokenDto, currentTenant: ITechTenantInfoSettings, isLogging: boolean }>()
);

export const successMultiTenant = createAction(
  '[Login-Tech] Multi tenant Success',
  props<{ tokenInfo: MeDto }>()
);

export const existingMultiTenant = createAction(
  '[Login-Tech] Multi tenant Existing',
  props<{ tenantId: number }>()
);

export const cancelSelectedTenant = createAction(
  '[Login-Tech] Selected tenant Cancel'
);

export const successSelectedTenant = createAction(
  '[Login-Tech] Selected tenant Success',
  props<{ tenantId?: number, tenantDisplayName?: string, isLogging?: boolean }>()
);

export const fetchTenantSettings = createAction(
  '[Login-Tech] Fetch Tenant Settings Start',
  props<{ tenantId: number }>()
);

export const fetchTenantSettingsCancel = createAction(
  '[Login-Tech] Fetch Tenant Settings Cancel'
);

export const fetchTenantSettingsSuccess = createAction(
  '[Login-Tech] Fetch Tenant Settings Success',
  props<{ currentTenant: ITechTenantInfoSettings }>()
);

export const fetchTenantSettingsFail = createAction(
  '[Login-Tech] Fetch Tenant Settings Fail',
  props<{ error: any }>()
);

export const isLoggingNavigation = createAction(
  '[Login-Tech] Login navigation is Logging Start'
);

export const isLoggingNavigationDone = createAction(
  '[Login-Tech] Login navigation is Logging Done'
);

export const isLoggingNavigationCanceled = createAction(
  '[Login-Tech] Login navigation is Logging Canceled'
);

export const navigationToInfos = createAction(
  '[Login-Tech] Navigate to Infos page'
);

export const navigationToWelcome = createAction(
  '[Login-Tech] Navigate to welcome page'
);
