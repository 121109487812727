<ion-spinner *ngIf="isLoading$ | async"></ion-spinner>
<ion-split-pane [when]="'md'" contentId="contentId" #ionSplitPane>
  <!--  left side-->
  <ion-menu contentId="contentId">
    <!--    header-->
    <ion-header>
      <ion-toolbar color="primary">
        <ion-buttons>
          <ion-button (click)="toggleMenu()">
            <ion-icon name="menu"></ion-icon>
          </ion-button>
          <ion-title>{{ 'GENERAL.NAVIGATION.MENU' | translate }}</ion-title>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <!--    content-->
    <ion-content class="ion-padding">
      <ion-list>
        <ng-container *ngFor="let article of articlesByStart as articles; index as i">
          <ion-item
            button="button"
            (click)="loadContent({
              content: article.content,
               title: article.title,
               attachments: article.attachments,
               articlesLength: articles.length,
               currentProgress: i
               })">
            <ion-label [ngClass]="{'selected-item': i === currentProgress}">
              {{ article.title }}
            </ion-label>
          </ion-item>
        </ng-container>
      </ion-list>
    </ion-content>
  </ion-menu>

  <!--  right side-->
  <div class="ion-page" id="contentId">
    <!--    header-->
    <ion-header>
      <ion-toolbar color="primary">
        <ng-container *ngIf="articlesByStart && isFirstLoad; else selectedTitle">
          <ng-container *ngIf="articlesByStart as articles">
            <ion-title [innerText]="articles[0].title">
            </ion-title>
          </ng-container>
        </ng-container>
        <ng-template #selectedTitle>
          <ion-title [innerText]="titleData">
          </ion-title>
        </ng-template>
        <ion-buttons slot="start">
          <ion-button *ngIf="ionSplitPane.disabled" (click)="toggleMenu()">
            <ion-icon name="menu"></ion-icon>
          </ion-button>
          <ion-button *ngIf="!ionSplitPane.disabled" (click)="back()">
            <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <!--    content-->
    <ion-content class="ion-padding">
      <ion-progress-bar [value]="progress"></ion-progress-bar>
      <ion-card [@contentChange]="contentData">
        <ng-container *ngIf="articlesByStart && isFirstLoad; else selectedValue">
          <ng-container *ngIf="articlesByStart as articles">
            <app-shared-knowledge-article-detail-start
              [htmlContent]="setInitialContent(articles)"
              [attachmentsInc]="articles[0].attachments"
            ></app-shared-knowledge-article-detail-start>
          </ng-container>
        </ng-container>
        <ng-template #selectedValue>
          <app-shared-knowledge-article-detail-start
            *ngIf="isContentAvailable"
            [htmlContent]="contentData"
            [attachmentsInc]="attachments"
          ></app-shared-knowledge-article-detail-start>
        </ng-template>
      </ion-card>
      <ion-fab class="navigation-buttons" slot="fixed" vertical="bottom" horizontal="end">
        <ion-row>
          <ion-col>
            <ion-fab-button (click)="previousPosition()" [disabled]="currentProgress === 0">
              <ion-icon name="chevron-back-outline"></ion-icon>
            </ion-fab-button>
          </ion-col>
          <ion-col>
            <ion-fab-button (click)="nextPosition()"
                            [disabled]="currentProgress === contentDataArray.length - 1">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </ion-fab-button>
          </ion-col>
        </ion-row>
      </ion-fab>
    </ion-content>
  </div>
</ion-split-pane>
