import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {select, Store} from "@ngrx/store";
import {exhaustMap, mergeMap, Observable, withLatestFrom} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {TeamIssueApiService} from "@team/pages/issues/services/team-issue-api.service";
import {TeamIssuesActions} from "@team/pages/issues/store/team-issue.actions-type";
import {
  AppEntityType,
  FileDto,
  FilterOperators,
  IssueContentType,
  IssueListDto,
  IssueTemplateListDto,
  IssueType,
  Orders,
  ResourceListDto,
  StereotypeDto,
  StereotypeListDto, WinterhalterProductCatalogDto
} from "@server-models";
import {Router} from "@angular/router";
import {SharedImageService} from "@shared/services/shared-image.service";
import {SharedCameraService} from "@shared/services/shared-camera.service";
import {SharedModalLoaderService} from "@shared/services/shared-modal-loader.service";
import {TranslateService} from "@ngx-translate/core";
import {IIssueRequestPagination} from "@shared/components/issues/interfaces/issue-request-pagination.interface";
import {
  ICustomPropertyStructureFormatted
} from "@shared/components/custom-property-form/interfaces/custom-property-structure-formatted.interface";
import {SharedIssueBaseService} from "@shared/components/issues/services/shared-issue-base.service";
import {SharedIssueApiBaseEffects} from "@shared/components/issues/store/shared-issue-api-base.effects";
import {SharedIssueBaseActions} from "@shared/components/issues/store/shared-issue-base.action-type";
import {
  TTemplatePreviewIssue
} from "@shared/components/issues/store/shared-issue-base.actions";
import {TeamIssueService} from "@team/pages/issues/services/team-issue.service";
import {SharedIssueFactory} from "@shared/components/issues/services/shared-issue.factory";
import {TeamIssueSelectors} from "@team/pages/issues/store/team-issue.selector-type";
import {FormGroup} from "@angular/forms";
import {IIssueService} from "@shared/components/issues/interfaces/issue-service.interface";
import {TTemplatePreviewDto} from "@shared/components/issues/types/template-preview-dto.type";
import {TSubmitIssueDto} from "@shared/components/issues/types/submit-issue-dto.type";
import {SharedIssueStereotypeBaseService} from "@shared/components/issues/services/stereotype/shared-issue-stereotype-base.service";
import {
  TeamIssueWinterhalterProductOrderApiService
} from "@team/pages/issues/services/winterhalter/team-issue-winterhalter-product-order-api.service";
import {SharedCacheService} from "@shared/services/cache/shared-cache.service";
import {TeamStereotypeApiService} from "@team/services/stereotype/team-stereotype-api.service";
import {SharedFileApiService} from "@shared/services/file/shared-file-api.service";

@Injectable({
  providedIn: 'root'
})
export class TeamIssueApiEffects extends SharedIssueApiBaseEffects {

  constructor(
    actions$: Actions,
    store: Store,
    _cameraService: SharedCameraService,
    _modalLoaderService: SharedModalLoaderService,
    _translateService: TranslateService,
    _imageService: SharedImageService,
    _issuesBaseService: SharedIssueBaseService,
    _issueBaseStereotype: SharedIssueStereotypeBaseService,
    _issuesBaseFactory: SharedIssueFactory,
    _cacheService: SharedCacheService,
    _router: Router,
    private _teamIssuesApiService: TeamIssueApiService,
    private _teamIssuesService: TeamIssueService,
    private _sharedFileApiService: SharedFileApiService,
    private _teamStereotypeApiService: TeamStereotypeApiService
  ) {
    super(
      actions$,
      store,
      _cameraService,
      _modalLoaderService,
      _translateService,
      _imageService,
      _issuesBaseService,
      _issueBaseStereotype,
      _issuesBaseFactory,
      _cacheService,
      _router
    );
  }

  _requestGetPaginated(action: IIssueRequestPagination): Observable<(IOrgaResponse<IssueListDto[]> | any)> {
    const body = [
      {
        property: "issueType",
        value: IssueType.Mission.toString(),
        operator: FilterOperators.NotEqual
      },
      {
        property: "issueType",
        value: IssueType.WinterhalterServiceCall.toString(),
        operator: FilterOperators.NotEqual
      },
      {
        property: "issueType",
        value: IssueType.WinterhalterProductOrder.toString(),
        operator: FilterOperators.NotEqual
      }
    ];

    const options = {
      params: {
        PageSize: action.params.pageSize.toString(),
        PageNumber: action.params.pageNumber.toString(),
        Cols: action.params.cols,
        SortField: "lastModified",
        Sort: Orders.Descending.toString()
      }
    }
    return this._teamIssuesApiService.postIssueRecentFilterList(body, options).pipe(
      map((data: IOrgaResponse<IssueListDto[]>) =>
        action.isRefresh
          ? TeamIssuesActions.getItemsPaginatedRefresh({ data })
          : TeamIssuesActions.getItemsPaginatedSuccess({ data })
      ),
      catchError((error) => [TeamIssuesActions.getItemsPaginatedFail({ error })])
    )
  }

  _requestGetTemplate(templatePreviewId: string): Observable<(IOrgaResponse<IssueTemplateListDto[]> | any)> {
    const body = [
      {
        property: "contentType",
        value: IssueContentType.Mission.toString(),
        operator: FilterOperators.NotEqual
      },
      {
        property: "contentType",
        value: IssueContentType.WinterhalterServiceCall.toString(),
        operator: FilterOperators.NotEqual
      },
      {
        property: "contentType",
        value: IssueContentType.WinterhalterProductOrder.toString(),
        operator: FilterOperators.NotEqual
      }
    ];

    return this._teamIssuesApiService.postIssueTemplateFilterList(body).pipe(
      map((data: IOrgaResponse<IssueTemplateListDto[]>) =>
        TeamIssuesActions.getItemsTemplateSuccess({ data, templatePreviewId })
      ),
      catchError((error) => [TeamIssuesActions.getItemsTemplateFail({ error })])
    );
  }

  override navigateToNewIssue(action$: Observable<any>) {
    return action$.pipe(
      tap(() => {
        this._router.navigate([`team/logged/issues/new`])
      })
    )
  }

  override navigateToIssues(action$: Observable<any>) {
    return action$.pipe(
      tap(() => {
        this._router.navigate([`team/logged/issues`])
      })
    )
  }

  override navigateToTemplatePreview(action$: Observable<{
    templateId: number,
    templateIssueType: IssueContentType
  }>): Observable<any> {
    return action$.pipe(
      tap((action) => {
        this._router.navigate([`team/logged/issues/new/${ action.templateId }`])
      })
    );
  }

  override formatReadDetailDataFinish(action$: Observable<{
    detailDataFormatted: ICustomPropertyStructureFormatted
  }>): Observable<any> {
    return action$.pipe(
      tap((action) => {
        this._router.navigate([`team/logged/issues/${ action.detailDataFormatted.structureId }`])
      })
    )
  }

  _requestByIdByIssueType(action: { id: number, issueType: IssueType }): Observable<any> {
    const apiService: IIssueService = this._teamIssuesService.getApiServiceByIssueType(action.issueType);
    return apiService.getIssueById(action.id).pipe(
      map((data) =>
        TeamIssuesActions.getByIdSuccess({ data, cacheGuid: this._cacheService.generateGuid() })
      ),
      catchError((error) => [TeamIssuesActions.getByIdFail({ error })])
    );
  }

  _requestGetStereotypeById(action: { stereotypeId: number, cacheGuid: string }): Observable<{
    stereotypeId: number,
    cacheGuid: string
  } | any> {
    return this._teamStereotypeApiService.getStereotypeById(action.stereotypeId).pipe(
      map((data: StereotypeDto) => {
          return TeamIssuesActions.getStereotypeByIdSuccess({ stereotype: data, cacheGuid: action.cacheGuid })
        }
      ),
      catchError((error) => [TeamIssuesActions.getStereotypeByIdFail({ error })])
    )
  }

  override _requestGetStereotypeByEntityType(entityType: AppEntityType,
                                             cacheControl: string): Observable<IOrgaResponse<StereotypeListDto> | any> {
    return this._teamStereotypeApiService.getStereotypeFilterByEntityType(entityType, cacheControl).pipe(
      map((data: IOrgaResponse<StereotypeListDto>) => {
          return TeamIssuesActions.getStereotypeByEntityTypeSuccess({ data })
        }
      ),
      catchError((error) => [TeamIssuesActions.getStereotypeByEntityTypeFail({ error })])
    )
  }

  override sendTemplatePreviewSuccess(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      exhaustMap((_) => {
        this._router.navigate([`team/logged/issues/success`]);
        return [TeamIssuesActions.thanksPageNavigation()]
      })
    );
  }

  override thanksPageNavigationBack(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      exhaustMap((_) => {
        this._router.navigate([`team/logged/issues`])
        return [SharedIssueBaseActions.thanksPageNavigationBackDone()]
      })
    );
  }

  _saveSignatureRequest(tenantId: number, blob: Blob, fileType: string, index: number): Observable<{
    signature: FileDto
  } | any> {
    return this._sharedFileApiService.upload(tenantId, blob, fileType).pipe(
      exhaustMap((data: FileDto) => {
          return [TeamIssuesActions.saveSignatureSuccess({
            id: data.fileId!,
            index
          })]
        }
      ),
      catchError((error) => [TeamIssuesActions.saveSignatureFail({ error })])
    )
  }

  _savePictureRequest(tenantId: number, blob: Blob, fileType: string, pictureId: number): Observable<{
    image: FileDto
  } | any> {
    return this._sharedFileApiService.upload(tenantId, blob, fileType).pipe(
      exhaustMap((data: FileDto) => {
          return [TeamIssuesActions.savePictureSuccess({ fileId: data.fileId!, path: data.path!, pictureId })]
        }
      ),
      catchError((error) => [TeamIssuesActions.savePictureFail({ error })])
    )
  }

  override _requestGetTemplatePreviewByIssueType(action: TTemplatePreviewIssue): Observable<(unknown | any)> {
    const apiService: IIssueService = this._teamIssuesService.getApiServiceByIssueContentType(action.issueType);
    return apiService.getIssueTemplatePreviewListById(action.id).pipe(
      map((data) =>
        TeamIssuesActions.getItemsTemplatePreviewSuccess({ data })
      ),
      catchError((error) => [TeamIssuesActions.getItemsTemplatePreviewFail({ error })])
    )
  }

  override requestItemsResource(action: any): Observable<any> {
    return this._teamIssuesApiService.requestResourceListByFilter([{}]).pipe(
      map((data: IOrgaResponse<ResourceListDto>) =>
        TeamIssuesActions.getItemsResourceSuccess({ data })
      ),
      catchError((error) => [TeamIssuesActions.getItemsResourceFail({ error })])
    )
  }

  override prepareFormsToSendTemplatePreview(action$: Observable<{
    form: FormGroup,
    templatePreviewDto: TTemplatePreviewDto
  }>): Observable<any> {
    return action$.pipe(
      withLatestFrom(this.store.pipe((select(TeamIssueSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([{ form, templatePreviewDto }, templateIssueType]) => {
        const service = this._issuesBaseFactory.getServiceByIssueType(templateIssueType);
        const submitReady = service.prepareFormIssueDto(form, templatePreviewDto);
        return [TeamIssuesActions.prepareFormsToSendTemplatePreviewDone({ submitReady })]
      })
    );
  }

  override sendTemplatePreview(action$: Observable<{
    issuePrepared: TSubmitIssueDto
  }>): Observable<void> {
    return action$.pipe(
      withLatestFrom(this.store.pipe(select(TeamIssueSelectors.selectSelectedTemplateId))),
      withLatestFrom(this.store.pipe((select(TeamIssueSelectors.selectSelectedTemplateIssueType)))),
      mergeMap(([[{ issuePrepared }, templateId], issueContentType]) => {
        return this._sendTemplatePreviewByIssueType({
          templateId,
          issuePrepared,
          issueContentType
        });
      })
    );
  }

  override _sendTemplatePreviewByIssueType(action: {
    templateId: number,
    issuePrepared: TSubmitIssueDto
    issueContentType: IssueContentType
  }): Observable<(unknown | any)> {
    const apiService = this._teamIssuesService.getApiServiceByIssueContentType(action.issueContentType);
    return apiService.sendIssueTemplatePreviewById(action.templateId, action.issuePrepared).pipe(
      map(() => TeamIssuesActions.sendTemplatePreviewSuccess({})),
      catchError(error => [TeamIssuesActions.sendTemplatePreviewFail({ error })])
    );
  }


  navigateToNewSpecificTemplate(action$: Observable<{ templateId: number }>): Observable<any> {
    return action$.pipe(
      tap((action) => {
        this._router.navigate([`team/logged/issues/new/${ action.templateId }`])
      })
    )
  }

  override getWinterhalterProductOrderCatalog(action$: Observable<any>): Observable<any> {
    return action$.pipe(
      mergeMap(([{ resourceId }]) => {
        return this._requestCatalogProducts({
          issueContentType: IssueContentType.WinterhalterProductOrder,
          resourceId: resourceId
        });
      })
    );
  }

  _requestCatalogProducts(action: { issueContentType: IssueContentType, resourceId: number }): Observable<any> {
    const apiService = this._teamIssuesService.getApiServiceByIssueContentType(action.issueContentType) as TeamIssueWinterhalterProductOrderApiService;
    return apiService.getCatalogByResourceId(action.resourceId).pipe(
      map((data: WinterhalterProductCatalogDto) => TeamIssuesActions.getWinterhalterProductOrderCatalogSuccess({
        data
      })),
      catchError(error => [TeamIssuesActions.getWinterhalterProductOrderCatalogFail({ error })])
    );
  }
}
