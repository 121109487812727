import {Injectable} from "@angular/core";
import {CoreBaseEffects} from "@core/core-base.effects";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Observable} from "rxjs";
import {TeamSideBarActions} from "@team/components/side-bar/store/team-side-bar.actions-type";

@Injectable({
  providedIn: 'root'
})
export abstract class SideBarEffectsBase extends CoreBaseEffects {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract getLogo(action$: Observable<any>): Observable<{
    file: Blob,
    imgLogo: string,
    name: string,
    id: number
  } | any>;

  getLogo$ = createEffect(() => this.actions().pipe(
    ofType(TeamSideBarActions.getLogo),
    action => this.getLogo(action)
  ));

}
