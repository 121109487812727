<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <ion-buttons>
      <ion-button *ngIf="!isRoot()" (click)="back()">
        <ion-icon slot="start" name="arrow-back-sharp"></ion-icon>
      </ion-button>
      <ng-container *ngIf="isRoot()">
        <app-shared-button-side-menu [menuId]="menuId" [isShowMenu]="isRoot()"
                                     [title]="'PAGES.DOCUMENTS.PAGE_NAME' | translate"></app-shared-button-side-menu>
      </ng-container>
      <ion-title [innerText]="getLastPosition(currentPath)"></ion-title>
      <ion-buttons>
        <ion-button (click)="changeDisplayBar()">
          <ion-icon name="search"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content pulling-icon="chevron-down"></ion-refresher-content>
  </ion-refresher>
  <app-shared-base-search-bar *ngIf="isDisplaySearchBar" class="item-searchbar"
                              (valueToSearch)="onSearch($event)"></app-shared-base-search-bar>
  <ion-spinner *ngIf="!isDragRefresh && (isLoading$ | async) && !isInfinityLoading"></ion-spinner>
  <ng-container *ngIf="!(!isDragRefresh && (isLoading$ | async) && !isInfinityLoading)">
    <ion-card class="card-container">
      <ion-item *ngIf="isRoot()" button="button" lines="full" (click)="myPersonal()">
        <ion-icon name="person-sharp" slot="start"></ion-icon>
        <ion-label [innerText]="'PAGES.DOCUMENTS.PAGE_NAME_PERSONAL' | translate"></ion-label>
      </ion-item>
      <app-shared-document-list (pathChange)="onPathChange($event)"
                                [documentList]="documentsData$ | async"></app-shared-document-list>
    </ion-card>
  </ng-container>
  <ion-infinite-scroll
    threshold="0px"
    (ionInfinite)="loadMoreDocuments($event)">
    <ion-infinite-scroll-content>
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
