import {createFeatureSelector, createSelector} from "@ngrx/store";
import {SharedDocumentState} from "@shared/components/documents/store/shared-document.state";

export const sharedDocumentStoreSelect = createFeatureSelector<SharedDocumentState>('sharedDocuments');

export const selectSharedDocumentsStore = createSelector(
  sharedDocumentStoreSelect,
  (state: SharedDocumentState) => state
);

export const selectDocumentsPage = createSelector(
  sharedDocumentStoreSelect,
  (state: SharedDocumentState) => state.page
);

export const selectDocumentsPageList = createSelector(
  sharedDocumentStoreSelect,
  (state: SharedDocumentState) => state.items
);

export const selectDocumentsPagePagination = createSelector(
  sharedDocumentStoreSelect,
  (state: SharedDocumentState) => state.paging
);

export const selectDocumentsPageLoading = createSelector(
  sharedDocumentStoreSelect,
  (state: SharedDocumentState) => state.isLoading
);

export const selectDocumentsPersonalPageList = createSelector(
  sharedDocumentStoreSelect,
  (state: SharedDocumentState) => state.personal.items
);

export const selectDocumentsPersonalPagePagination = createSelector(
  sharedDocumentStoreSelect,
  (state: SharedDocumentState) => state.personal.paging
);

export const selectDocumentsPersonalPageLoading = createSelector(
  sharedDocumentStoreSelect,
  (state: SharedDocumentState) => state.personal.isLoading
);
