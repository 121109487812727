import {CoreBaseEffects} from "@core/core-base.effects";
import {Observable} from "rxjs";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {TeamInfoActions} from "@team/pages/infos/store/team-info.actions-type";
import {Injectable} from "@angular/core";
import {IInfosRequestPagination} from "@team/pages/infos/interfaces/infos-request-pagination.interface";

@Injectable({
  providedIn: 'root'
})
export abstract class TeamInfoBaseEffects extends CoreBaseEffects {

  protected constructor(store: Store, rawActions$: Actions) {
    super(store, rawActions$);
  }

  abstract postPaginated(action$: Observable<IInfosRequestPagination>): Observable<any>;

  abstract postMyRead(action$: Observable<any>): Observable<any>;

  abstract postItemsSearchedPaginated(action$: Observable<{
    searchTerm: string;
    requestParam: IInfosRequestPagination
  }>): Observable<any>;


  postPaginated$ = createEffect(() => this.actions().pipe(
    ofType(TeamInfoActions.postItemsPaginated),
    action => this.postPaginated(action)
  ));

  postMyRead$ = createEffect(() => this.actions().pipe(
    ofType(TeamInfoActions.postItemsMyRead),
    action => this.postMyRead(action)
  ));

  postItemsSearchedPaginated$ = createEffect(() => this.actions().pipe(
    ofType(TeamInfoActions.postItemsSearchedPaginated),
    action => this.postItemsSearchedPaginated(action)
  ));

}
