<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <app-shared-button-side-menu [menuId]="'team-menu'"
                                 [title]="'TEAM.ISSUES.PAGES.MAIN.PAGE_NAME' | translate"></app-shared-button-side-menu>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-fab class="navigation-buttons" slot="fixed" vertical="bottom" horizontal="end">
    <ion-row>
      <ion-col>
        <ion-fab-button (click)="gotoAdd()">
          <ion-icon name="add-outline"></ion-icon>
        </ion-fab-button>
      </ion-col>
    </ion-row>
  </ion-fab>
  <ion-spinner *ngIf="!isDragRefresh && (isLoading$ | async) && !isInfinityLoading"></ion-spinner>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content pulling-icon="chevron-down"></ion-refresher-content>
  </ion-refresher>

  <ion-card *ngIf="!(!isDragRefresh && (isLoading$ | async) && !isInfinityLoading)">
    <ion-card-content class="space-content">
      <ng-container *ngIf="(issuesData$ | async)?.length! > 0; else noIssues">
        <ng-container *ngFor="let issue of issuesData$ | async as issueList">
          <app-shared-issue-item (navigateToIssueEvent)="onNavigateToIssue()" [issue]="issue"></app-shared-issue-item>
        </ng-container>
      </ng-container>
      <ng-template #noIssues>
        <ion-item>
          <ion-label class="ion-text-center" [innerText]="'TEAM.ISSUES.PAGES.MAIN.EMPTY' | translate"></ion-label>
        </ion-item>
      </ng-template>
    </ion-card-content>
  </ion-card>

  <ion-infinite-scroll
    (ionInfinite)="loadMoreIssues($event)">
    <ion-infinite-scroll-content>
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>

