import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule, ViewWillEnter} from '@ionic/angular';
import {Store} from "@ngrx/store";
import {filter, Observable} from "rxjs";
import {Params, Router} from "@angular/router";
import {SharedShowHidePasswordComponent} from "@shared/components/show-hide-password/shared-show-hide-password.component";
import {TranslateModule} from "@ngx-translate/core";
import {TeamLoginCardComponent} from "@team/pages/login/components/card/team-login-card.component";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";
import {EAppType} from "@shared/models/AppType.enum";
import {TokenDto} from '@server-models';

@Component({
  selector: 'app-team-login',
  templateUrl: './team-login.page.html',
  styleUrls: ['./team-login.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, FormsModule, SharedShowHidePasswordComponent, TranslateModule, TeamLoginCardComponent]
})
export class TeamLoginPage implements OnInit, ViewWillEnter {

  public isLoading$: Observable<boolean> = this._store.select(TeamLoginSelectors.selectIsLoading);
  public isLogged$: Observable<boolean> = this._store.select(TeamLoginSelectors.selectIsLogged)
  public token$: Observable<TokenDto | undefined> = this._store.select(TeamLoginSelectors.selectToken);

  username?: string;
  password?: string;

  redirectRequest?: string;
  isRegisterView: boolean;

  constructor(
    private _store: Store,
    private _router: Router,
  ) {
    this.isRegisterView = false;
  }


  ionViewWillEnter(): void {
    this.redirectRequest = this.getRedirectRequest();
    this.isLogged$.subscribe((isLogged) => {
      if (isLogged && this.redirectRequest === undefined) {
        this._router.navigate(['team/logged/infos'])
      }
    });
    this.token$.pipe(
      filter(token => !!token)
    ).subscribe(async () => {
      if (this.redirectRequest !== undefined) {
        await this.startApp(EAppType.Team);
      }
    });
    this._store.dispatch(TeamLoginActions.init());
  }

  ngOnInit() {

  }

  async startApp(action: EAppType) {
    const redirect = await this.findRedirectUrl(action, this.redirectRequest);
    const url = new URL(redirect ?? '', window.location.origin);
    const path = url.pathname;

    const queryParams: Params = {};
    url.searchParams.forEach((value, key) => {
      queryParams[key] = value;
    });

    return this._router.navigate([path], { queryParams });
  }

  getRedirectRequest(): string | undefined {
    const queryParams = new URLSearchParams(window.location.search);
    const url = queryParams?.get('redirect');

    return url || undefined;
  };

  async findRedirectUrl(appType: EAppType, request?: string) {
    switch (appType) {
      case EAppType.Team:
        return request?.startsWith('/team/') ? request : '/team/';
      default:
        return null;
    }
  }

  /**
   * @name onUserSubmit
   * @description
   * handle the submit event of the childs to dispatch the correct action
   * @memberof TeamLoginPage
   * @param ev
   */
  onUserSubmit(ev: any) {
    const inputUsername = ev.input.username;
    const inputPassword = ev.input.password;

    this._store.dispatch(TeamLoginActions.byPassword({ login: inputUsername!, password: inputPassword || '' }));
  }

  /**
   * @name onToLogin
   * @description
   * control the emitted value to change the view
   * @memberof TeamLoginPage
   */
  onToLogin(): void {
    this.isRegisterView = !this.isRegisterView;
  }

  /**
   * @name onToReset
   * @description
   * navigate to reset password
   * @memberof TeamLoginPage
   */
  onToReset(): void {
    this._router.navigate(['team/login/reset-password']);
  }

  onFormChange(ev: any): void {
    this.username = ev.input.username;
  }

  /**
   * @name onRegisterUser
   * @description
   * handle on register user event to dispatch init registration guest
   * @memberof TeamLoginPage
   */
  onRegisterUser(buttonId: number | string): void {
    this._store.dispatch(TeamLoginActions.initRegistrationGuest({
      isLoadingId: buttonId,
      currentEmail: this.username
    }));
  }
}
