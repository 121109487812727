<ion-header [translucent]="true">
  <ion-toolbar color="primary">
    <app-shared-button-side-menu
      [menuId]="'team-menu'"
      [title]="isInfosRead ? ('TEAM.INFOS.PAGE_NAME.READ' | translate) : ('TEAM.INFOS.PAGE_NAME.UN_READ' | translate)">
      <div class="right-margin">
        <ion-button *ngIf="isInfosRead" (click)="filterInfosByUnread()">
          <ion-icon name="eye-off-outline"></ion-icon>
        </ion-button>
        <ion-button *ngIf="!isInfosRead" (click)="filterInfosByRead()">
          <ion-icon name="eye-outline"></ion-icon>
        </ion-button>
        <ion-button (click)="changeDisplayBar()">
          <ion-icon name="search"></ion-icon>
        </ion-button>
      </div>
    </app-shared-button-side-menu>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content pulling-icon="chevron-down"></ion-refresher-content>
  </ion-refresher>
  <app-shared-base-search-bar *ngIf="isDisplaySearchBar" class="item-searchbar"
                              (valueToSearch)="onSearch($event)"></app-shared-base-search-bar>
  <ion-spinner *ngIf="!isDragRefresh && (isLoading$ | async) && !isInfinityLoading"></ion-spinner>


  <ng-container *ngIf="!(!isDragRefresh && (isLoading$ | async) && !isInfinityLoading)">
    <ng-container *ngIf="(infosData$ | async)?.length! > 0; else noInfo">
      <ng-container *ngFor="let info of infosData$ | async as infos">
        <app-team-info-card [info]="info"></app-team-info-card>
      </ng-container>
    </ng-container>
  </ng-container>

  <ion-infinite-scroll
    (ionInfinite)="loadMoreInfos($event)">
    <ion-infinite-scroll-content>
    </ion-infinite-scroll-content>
  </ion-infinite-scroll>
</ion-content>
<ng-template #noInfo>
  <ion-card class="card-container">
    <ion-item>
      <ion-label class="ion-text-center" [innerText]="'TEAM.INFOS.EMPTY' | translate"></ion-label>
    </ion-item>
  </ion-card>
</ng-template>

