import {Component, Input, OnInit} from '@angular/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import * as _ from 'lodash';
import {Store} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {TechLoginActions} from '@tech/pages/login/store/tech-login.actions-type';
import {SharedTenantFilterPipe} from '@shared/pipes/shared-tenant-filter.pipe';
import type {TenantInfoDto} from '@server-models';
import {TeamLoginActions} from '@team/pages/login/store/team-login.actions-type';
import {EAppType} from '@shared/models/AppType.enum';
import {SharedLoginBaseSelectors} from '@shared/stores/login-base/store/shared-login-base.selector-type';
import {firstValueFrom} from 'rxjs';
import {SharedSearchBarComponent} from '@shared/components/search-bar/shared-search-bar.component';

@Component({
  selector: 'app-shared-tenant-selection-modal',
  templateUrl: './shared-tenant-selection-modal.component.html',
  styleUrls: ['./shared-tenant-selection-modal.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    TranslateModule,
    SharedTenantFilterPipe,
    SharedSearchBarComponent
  ],
  standalone: true,
})
export class SharedTenantSelectionModalComponent implements OnInit {
  @Input() public data!: TenantInfoDto[];

  selectedRoles!: { tenantId: number };
  searchTerm: string = '';
  currentApp: EAppType = EAppType.Team;

  constructor(
    private _modalCtrlService: SharedModalControllerService,
    private _store: Store
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.currentApp = await firstValueFrom(
      this._store.select(SharedLoginBaseSelectors.selectCurrentApp)
    );
  }

  get sortedData() {
    return _.sortBy(this.data, ['displayName']);
  }

  submitSelection(): void {
    switch (this.currentApp) {
      case EAppType.Team:
        this._store.dispatch(
          TeamLoginActions.successSelectedTenant(this.selectedRoles)
        );
        break;
      case EAppType.Tech:
        this._store.dispatch(
          TechLoginActions.successSelectedTenant(this.selectedRoles)
        );
        break;
    }
    this._modalCtrlService.closeModal();
  }

  exit(): void {
    this._modalCtrlService.closeModal();
    this._store.dispatch(TechLoginActions.cancelSelectedTenant());
  }
}
