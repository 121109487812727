import {Component, Input, OnInit} from '@angular/core';
import {SharedAttachmentsComponent} from "@shared/components/attachments/shared-attachments.component";
import {IonicModule} from "@ionic/angular";
import {NgIf} from "@angular/common";
import {SharedUserHtmlComponent} from "@shared/components/user-html/shared-user-html.component";

@Component({
  selector: 'app-shared-knowledge-article-detail-start',
  templateUrl: './shared-knowledge-article-detail-start.component.html',
  styleUrls: ['./shared-knowledge-article-detail-start.component.scss'],
  imports: [
    SharedAttachmentsComponent,
    IonicModule,
    NgIf,
    SharedUserHtmlComponent
  ],
  standalone: true
})
export class SharedKnowledgeArticleDetailStartComponent implements OnInit {

  @Input() htmlContent: any;
  @Input() attachmentsInc: any;

  constructor() {
  }

  ngOnInit() {
  }


}
