import {Component, OnDestroy, OnInit} from "@angular/core";
import {IonicModule} from "@ionic/angular";
import {AsyncPipe, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {Observable, Subscription, take} from "rxjs";
import {select, Store} from "@ngrx/store";
import {SharedLoginBaseSelectors} from "@shared/stores/login-base/store/shared-login-base.selector-type";
import {SharedLoginBaseState} from "@shared/stores/login-base/store/shared-login-base.state";
import {Orders} from "@server-models";
import {ActivatedRoute, Router, UrlSegment} from "@angular/router";
import {SharedSearchBarComponent} from "@shared/components/search-bar/shared-search-bar.component";
import {SharedDocumentListComponent} from "@shared/components/documents/components/list/shared-document-list.component";
import {SharedButtonSideMenuComponent} from "@shared/components/button-side-menu/shared-button-side-menu.component";
import {SharedDocumentsActions} from "@shared/components/documents/store/shared-document.actions-type";
import {SharedDocumentsSelectors} from "@shared/components/documents/store/shared-document.selectors-type";
import {SharedDocumentState} from "@shared/components/documents/store/shared-document.state";

@Component({
  selector: 'app-shared-document-personal',
  templateUrl: './shared-document-personal.page.html',
  styleUrls: ['./shared-document-personal.page.scss'],
  imports: [
    IonicModule,
    NgIf,
    TranslateModule,
    AsyncPipe,
    SharedSearchBarComponent,
    SharedDocumentListComponent,
    SharedButtonSideMenuComponent
  ],
  standalone: true
})
export class SharedDocumentPersonalPage implements OnInit, OnDestroy {
  // @Input() menuId!: string;

  sharedLoginModel$: Observable<SharedLoginBaseState> = this._store.pipe(select(SharedLoginBaseSelectors.selectSharedLoginBaseState));
  sharedDocumentsStore$: Observable<SharedDocumentState> = this._store.pipe(select(SharedDocumentsSelectors.selectSharedDocumentsStore));
  allSubscriptions: Subscription[] = [];
  currentRoute: UrlSegment[] = this._activatedRoute.snapshot.url;
  paginationParams = {
    pageNumber: 1,
    pageSize: 25,
    cols: 'Content',
    sortField: 'Type',
    sort: Number(Orders.Descending),
    sortThenField: 'Name',
    sortThen: Number(Orders.Ascending)
  };
  menuId!: string;

  constructor(
    private _store: Store,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
  }

  ngOnInit(): void {
    this._activatedRoute.data.subscribe(({ data }) => {
      this.menuId = data?.menuId;
    });
    this.loadDocumentsPersonalByPagination(true);
  }

  loadDocumentsPersonalByPagination(isRefresh: boolean = false): void {
    this.allSubscriptions.push(
      this.sharedLoginModel$.pipe(
        take(1)
      ).subscribe(
        (loginModel) => {
          this._store.dispatch(SharedDocumentsActions.getItemsPersonalPaginated({
            tenantId: loginModel.currentTenant?.tenantId!,
            path: '',
            params: this.paginationParams,
            isRefresh: isRefresh
          }));
        }
      )
    );
  }

  async back() {
    await this._router.navigate(['../'], { relativeTo: this._activatedRoute });
  }

  ngOnDestroy(): void {
    this.allSubscriptions.map((subscription: Subscription) => subscription.unsubscribe());
  }
}
