import {createFeatureSelector, createSelector} from "@ngrx/store";
import {TechLoginState} from "./tech-login.state";

export const techLoginSelect = createFeatureSelector<TechLoginState>('login');

export const selectCurrentApp = createSelector(
  techLoginSelect,
  (state: TechLoginState) => state.currentApp
);

export const selectIsLogging = createSelector(
  techLoginSelect,
  (state: TechLoginState) => state.isLogging
);

export const selectIsLogged = createSelector(
  techLoginSelect,
  (state: TechLoginState) => state.isLogged
);
