import {createReducer, on} from "@ngrx/store";
import {TeamSideBarActions} from "@team/components/side-bar/store/team-side-bar.actions-type";
import {TeamSideBarState} from "@team/components/side-bar/store/team-side-bar.state";

export const initialSideBar: TeamSideBarState = <TeamSideBarState>{
  name: "",
  path: "",
  file: <Blob>{},
  logo: "",
  isLoading: true,
  isLoadingId: NaN
};

export const teamSideBarStore = createReducer(
  initialSideBar,
  on(TeamSideBarActions.getLogo, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(TeamSideBarActions.getLogoSuccess, (state, args) => ({
    ...state,
    logo: args.imgLogo,
    isLoading: false,
  })),
  on(TeamSideBarActions.getLogoFail, (state) => ({
    ...state,
    isLoading: false
  })),
);
