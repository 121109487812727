import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {AppEntityType, StereotypeDto, StereotypeListDto} from "@server-models";
import {SharedStereotypeApiDefinitionService} from "@shared/services/stereotype/shared-stereotype-api-definition.service";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {IRequestOptions} from "@shared/interfaces/request-options.interface";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export class TeamStereotypeApiService extends CoreHttpClientApiBaseService implements SharedStereotypeApiDefinitionService {
  /**
   * @name getStereotypeById
   * @description
   * make a request to stereotype by id
   * @memberof TeamStereotypeApiService
   * @param id
   * @returns {Observable<StereotypeDto>}
   */
  getStereotypeById(id: number): Observable<StereotypeDto> {
    return super.get<StereotypeDto>(`/tech/stereotype/${ id }`, undefined);
  }

  /**
   * @name getStereotypeFilterByEntityType
   * @description
   * make a request to stereotype using entityType and cache-control
   * @memberof TeamStereotypeApiService
   * @param entityType
   * @param cacheControl
   * @returns {Observable<IOrgaResponse<StereotypeListDto>>}
   */
  getStereotypeFilterByEntityType(entityType: AppEntityType,
                                  cacheControl: string): Observable<IOrgaResponse<StereotypeListDto>> {
    const options = {
      params: {
        entityType: entityType.toString(),
        Cols: 'CustomPropertySets',
        'cache-control': cacheControl
      }
    }
    return super.get<IOrgaResponse<StereotypeListDto>>(`/tech/stereotype`, undefined, options);
  }

  /**
   * @name postStereotypeFilterByEntityTypePaginated
   * @description
   * post paginated stereotype filter by entity type
   * @memberof TeamStereotypeApiService
   * @param paramsPaginated
   * @param body
   * @return {Observable<IOrgaResponse<StereotypeListDto[]>>}
   */
  postStereotypeFilterByEntityTypePaginated(paramsPaginated: IPaginationParams,
                                            body: any): Observable<IOrgaResponse<StereotypeListDto[]>> {
    const options: IRequestOptions = {
      params: {
        PageSize: paramsPaginated.pageSize.toString(),
        PageNumber: paramsPaginated.pageNumber.toString(),
        Cols: paramsPaginated.cols,
        ...(paramsPaginated.entityType && { entityType: paramsPaginated.entityType.toString() }),
        ...(paramsPaginated.archived && { archived: paramsPaginated.archived.toString() }),
      }

    }
    return super.post<IOrgaResponse<StereotypeListDto[]>>(`/tech/stereotype/filter`, undefined, body, options);
  }
}
