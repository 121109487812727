import {mapToCanActivate, Route, RouterStateSnapshot} from "@angular/router";
import {TeamMainPage} from "./pages/team-main.page";
import {TeamChatPage} from "./pages/chat/team-chat.page";
import {TeamInfoPage} from "./pages/infos/team-info.page";
import {TeamKnowledgePage} from "./pages/knowledge/team-knowledge.page";
import {TeamChatResolver} from "./pages/chat/services/team-chat.resolver";
import {
  TeamKnowledgeDetailStartComponent
} from "@team/pages/knowledge/components/detail-start/team-knowledge-detail-start.component";
import {knowledgeDetailResolver} from "@team/pages/knowledge/store/knowledge-detail.resolver";
import {TeamLoginPage} from "@team/pages/login/team-login.page";
import {TeamChangePasswordPage} from "@team/pages/change-password/team-change-password.page";
import {TeamLoginChangePasswordGuard} from "@team/guards/team-login-change-password.guard";
import {teamLoginChangePasswordResolver} from "@team/pages/login/store/team-login-change-password.resolver";
import {inject} from "@angular/core";
import {EAppType} from "@shared/models/AppType.enum";
import {TeamAppLoginGuard} from "@team/guards/team-app-login.guard";
import {TeamResetPasswordPage} from "@team/pages/reset-password/team-reset-password.page";
import {TeamIssuePage} from "@team/pages/issues/team-issue.page";
import {TeamIssueReadGuard} from "@team/guards/team-issue-read.guards";
import {SharedIssueReadOnlyPage} from "@shared/components/issues/pages/read-only/shared-issue-read-only.page";
import {
  SharedIssueNewTemplateDetailPage
} from "@shared/components/issues/pages/new-template-detail/shared-issue-new-template-detail.page";
import {TeamWelcomePage} from "@team/pages/welcome/team-welcome.page";
import {TeamWelcomeGuard} from "@team/guards/team-welcome.guard";
import {TeamLoginPageGuard} from "@team/guards/team-login-page.guard";
import {
  SharedIssueSuccessPage
} from "@shared/components/issues/pages/success/shared-issue-success.page";
import {TeamArticlePage} from "@team/pages/knowledge/components/articles/team-article.page";
import {articlesPageResolver} from "@team/pages/knowledge/store/articles-page.resolver";
import {
  SharedIssueNewTemplateListPage
} from "@shared/components/issues/pages/new-template-list/shared-issue-new-template-list.page";
import {SharedDocumentPersonalPage} from "@shared/components/documents/pages/personal/shared-document-personal.page";
import {SharedDocumentPage} from "@shared/components/documents/shared-document.page";
import {teamMenuResolver} from "@team/resolvers/team-menu.resolver";

export default [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'team',
  },
  {
    path: 'team',
    children: [
      {
        path: '',
        redirectTo: 'login',
        pathMath: 'full'
      },
      {
        path: 'welcome',
        component: TeamWelcomePage,
        canActivate: mapToCanActivate([TeamWelcomeGuard])
      },
      {
        path: 'login',
        component: TeamLoginPage,
        canActivate: mapToCanActivate([TeamLoginPageGuard])
      },
      {
        path: 'login/reset-password',
        component: TeamResetPasswordPage
      },
      {
        path: 'login/change-password',
        component: TeamChangePasswordPage,
        resolve: { loginData: teamLoginChangePasswordResolver },
        canActivate: mapToCanActivate([TeamLoginChangePasswordGuard])
      },
      {
        path: 'logged',
        component: TeamMainPage,
        canActivate: [
          (_: any, state: RouterStateSnapshot) => inject(TeamAppLoginGuard).canActivate((EAppType.Team), state)
        ],
        children: [
          {
            path: '',
            redirectTo: 'infos',
          },
          {
            path: 'infos',
            component: TeamInfoPage,
          },
          {
            path: 'chat',
            component: TeamChatPage,
            resolve: {
              chat: TeamChatResolver,
            },
          },
          {
            path: 'knowledge',
            component: TeamKnowledgePage,
          },
          {
            path: 'knowledge/:id',
            component: TeamArticlePage,
            resolve: { article: articlesPageResolver }
          },
          {
            path: 'knowledge/:id/start',
            component: TeamKnowledgeDetailStartComponent,
            resolve: { detail: knowledgeDetailResolver },
          },
          {
            path: 'documents',
            component: SharedDocumentPage,
            resolve: { data: teamMenuResolver },
          },
          {
            path: 'documents/me',
            component: SharedDocumentPersonalPage,
            resolve: { data: teamMenuResolver },
          },
          {
            path: 'documents/:path',
            component: SharedDocumentPage,
            resolve: { data: teamMenuResolver },
            pathMatch: 'full',
          },
          {
            path: 'issues',
            component: TeamIssuePage,
          },
          {
            path: 'issues/new',
            component: SharedIssueNewTemplateListPage
          },
          {
            path: 'issues/new/:id',
            component: SharedIssueNewTemplateDetailPage,
            pathMatch: 'full',
          },
          {
            path: 'issues/success',
            component: SharedIssueSuccessPage,
            pathMatch: 'full'
          },
          {
            path: 'issues/:id',
            component: SharedIssueReadOnlyPage,
            canActivate: mapToCanActivate([TeamIssueReadGuard]),
            pathMatch: 'full',
          },
        ]
      },

    ],
  },
] as Route[];

