import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from "@ngx-translate/core";
import {RouterLink} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {TeamLoginActions} from "@team/pages/login/store/team-login.actions-type";
import {Observable, of} from "rxjs";
import {TeamLoginSelectors} from "@team/pages/login/store/team-login.selector-type";

@Component({
  selector: 'app-team-welcome',
  templateUrl: './team-welcome.page.html',
  styleUrls: ['./team-welcome.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, RouterLink]
})
export class TeamWelcomePage implements OnInit {

  isLoading$: Observable<boolean>

  constructor(
    private _store: Store
  ) {
    this.isLoading$ = of(true);
  }

  ngOnInit() {
    this.isLoading$ = this._store.pipe(select(TeamLoginSelectors.selectIsLoading));
  }

  joinTeam() {
    this._store.dispatch(TeamLoginActions.initInvitationCodeGuest())
  }

  logOut() {
    this._store.dispatch(TeamLoginActions.initLogout());
  }
}
