import {Component, Input, OnInit} from '@angular/core';
import {IonicModule, MenuController} from "@ionic/angular";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-shared-button-side-menu',
  templateUrl: './shared-button-side-menu.component.html',
  styleUrls: ['./shared-button-side-menu.component.scss'],
  imports: [
    CommonModule,
    IonicModule
  ],
  standalone: true
})
export class SharedButtonSideMenuComponent implements OnInit {

  @Input() title!: string;
  @Input() quantity!: string | number;
  @Input() isShowMenu!: boolean;
  @Input() menuId!: string;

  constructor(
    private _menuCtrl: MenuController
  ) {
    this.isShowMenu = true;
  }

  ngOnInit() {

  }

  /**
   * @name openMenu
   * @description
   * Use the menu controller to open the menu id
   * @memberof SharedButtonSideMenuComponent
   */
  openMenu() {
    this._menuCtrl.open(this.menuId)
  }

}
