import {EventEmitter, Injectable} from "@angular/core";
import {IKnowledgeDetailStartEvent} from "../interfaces/knowledge-detail-start-event.interface";

@Injectable({
  providedIn: 'root'
})
export class TeamKnowledgeDetailService {
  titleClicked: EventEmitter<IKnowledgeDetailStartEvent> = new EventEmitter<IKnowledgeDetailStartEvent>();

  /**
   * @name emitTitleClick
   * @description
   * emit the event<KnowledgeDetailStartEvent>
   * @memberof TeamKnowledgeDetailService
   * @param data
   */
  emitTitleClick(data: IKnowledgeDetailStartEvent): void {
    this.titleClicked.emit(data);
  }
}
