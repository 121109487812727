import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FilterDto, FilterOperators, InfoListDto, InfoState, Orders} from "@server-models";
import {filter, Observable, Subscription} from "rxjs";
import {TranslateModule} from "@ngx-translate/core";
import {
  selectInfoPageList,
  selectInfoPageLoading,
  selectInfoPagePagination
} from "@team/pages/infos/store/team-info.selectors";
import {select, Store} from "@ngrx/store";
import {IPagination} from "@shared/interfaces/pagination.interface";
import {TeamInfoState} from "@team/pages/infos/store/team-info.state";
import {TeamInfoCardComponent} from "@team/pages/infos/components/card/team-info-card.component";
import {tap} from "rxjs/operators";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {TeamInfoActions} from "@team/pages/infos/store/team-info.actions-type";
import {SharedSearchBarComponent} from "@shared/components/search-bar/shared-search-bar.component";
import {SharedButtonSideMenuComponent} from "@shared/components/button-side-menu/shared-button-side-menu.component";

@Component({
  selector: 'app-team-info',
  templateUrl: './team-info.page.html',
  styleUrls: ['./team-info.page.scss'],
  standalone: true,
  imports: [IonicModule, CommonModule, TranslateModule, TeamInfoCardComponent, SharedSearchBarComponent, SharedButtonSideMenuComponent],
})
export class TeamInfoPage implements OnInit {
  paginationParams: IPaginationParams;
  paginationSearchParams: IPaginationParams;
  paginationBody: FilterDto[];
  paginationSearchBody: FilterDto[];
  paginationBodyUnread: FilterDto[];
  defaultPaginationBody: FilterDto[];
  isDragRefresh: boolean;
  isInfinityLoading: boolean;

  infosData$: Observable<InfoListDto[]>;
  pagingData$: Observable<IPagination>;
  isLoading$: Observable<boolean>;
  isInfosRead: boolean;
  isDisplaySearchBar: boolean;
  currentSearchTerm: string;

  openSubscriptionList$: Subscription[];

  constructor(
    private _store: Store<TeamInfoState>
  ) {
    this.infosData$ = this._store.pipe(select(selectInfoPageList));
    this.pagingData$ = this._store.pipe(select(selectInfoPagePagination));
    this.isLoading$ = this._store.pipe(select(selectInfoPageLoading));
    this.openSubscriptionList$ = [];
    this.isDragRefresh = false;
    this.isInfinityLoading = false;
    this.isInfosRead = true;
    this.isDisplaySearchBar = false;
    this.currentSearchTerm = '';
    this.paginationParams = {
      pageNumber: 1,
      pageSize: 10,
      cols: 'Content',
      sortField: 'DateTime',
      sort: Number(Orders.Descending)
    };
    this.paginationSearchParams = {
      pageNumber: 1,
      pageSize: 10,
      cols: 'Content',
      sortField: 'DateTime',
      sort: Number(Orders.Descending)
    };
    this.defaultPaginationBody = [
      {
        "property": "state",
        "value": InfoState.Pending.toString(),
        "operator": FilterOperators.NotEqual

      }
    ]
    this.paginationBody = [
      {
        "property": "state",
        "value": InfoState.Pending.toString(),
        "operator": FilterOperators.NotEqual
      }
    ];
    this.paginationSearchBody = [
      {
        "property": "state",
        "value": InfoState.Pending.toString(),
        "operator": FilterOperators.NotEqual

      }
    ];
    this.paginationBodyUnread = [
      {
        "property": "isRead",
        "value": false.toString(),
        "operator": FilterOperators.Equal
      },
      {
        "property": "requestRead",
        "value": true.toString(),
        "operator": FilterOperators.Equal
      }
    ]
  }

  ngOnInit() {
    this.loadInfosByPagination();
  }

  /**
   * @name loadInfosByPagination
   * @description
   * launch the action to load infos by a post params and body
   * @memberof TeamInfoPage
   * @param isRefresh
   */
  loadInfosByPagination(isRefresh: boolean = false): void {
    this._store.dispatch((TeamInfoActions.postItemsPaginated({
      params: this.paginationParams,
      body: this.paginationBody,
      isRefresh: isRefresh
    })));
  }

  /**
   * @name loadMoreInfos
   * @description
   * request the launch once the infinity scroll bottom is reached
   * @memberof TeamInfoPage
   * @param event
   */
  loadMoreInfos(event: any): void {
    this.isInfinityLoading = true;

    if (this.isDisplaySearchBar) {
      this.paginationSearchParams.pageNumber++;
      this.loadInfosSearchByPagination(this.currentSearchTerm)

    } else {
      this.paginationParams.pageNumber++;
      this.loadInfosByPagination();
    }

    this.isLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        event.target.complete();
        this.isInfinityLoading = false;
      })
    ).subscribe();
  }

  /**
   * @name handleRefresh
   * @description
   * Use to reset the pageNumber param, and call with isRefresh flag loadInfos
   * @memberof TeamInfoPage
   * @param event
   */
  handleRefresh(event: any): void {
    if (this.isDragRefresh) {
      if (event && event.target) {
        event.target.complete();
      }
      return;
    }

    this.isDragRefresh = true;
    if (event) {
      event.target.disabled = true;
    }

    this.paginationParams.pageNumber = 1;
    this.loadInfosByPagination(true);

    this.isLoading$.pipe(
      filter((isLoading: boolean) => !isLoading),
      tap(() => {
        if (event && event.target) {
          event.target.disabled = false;
          event.target.complete();
        }
        this.isDragRefresh = false;
      })
    ).subscribe();
  }

  /**
   * @name filterInfosByUnread
   * @description
   * change pagination body to pagination body unread to filter by unread infos
   * @memberof TeamInfoPage
   */
  filterInfosByUnread(): void {
    this.isInfosRead = false;
    if (this.isDisplaySearchBar) {
      if (this.currentSearchTerm) {
        this._resetBodySearchParams();
        this.paginationSearchBody.push(this.paginationBodyUnread[0], this.paginationBodyUnread[1]);
        this.paginationSearchParams.pageNumber = 1;
        this.loadInfosSearchByPagination(this.currentSearchTerm, true);
      }
    } else {
      this._resetBodyParams();
      this.paginationBody.push(this.paginationBodyUnread[0], this.paginationBodyUnread[1]);
      this.paginationParams.pageNumber = 1;
      this.loadInfosByPagination(true);
    }

  }

  /**
   * @name filterInfosByRead
   * @description
   * change pagination body to pagination by default to filter by read infos
   * @memberof TeamInfoPage
   */
  filterInfosByRead(): void {
    this.isInfosRead = true;
    if (this.isDisplaySearchBar) {
      if (this.currentSearchTerm) {
        this._resetBodySearchParams();
        this.paginationSearchParams.pageNumber = 1;
        this.loadInfosSearchByPagination(this.currentSearchTerm, true);
      }
    } else {
      this._resetBodyParams();
      this.paginationParams.pageNumber = 1;
      this.loadInfosByPagination(true);
    }
  }

  /**
   * @name changeDisplayBar
   * @description
   * change boolean value of display search bar to hide or display
   * @memberof TeamInfoPage
   */
  changeDisplayBar() {
    this.isDisplaySearchBar = !this.isDisplaySearchBar;
    if (!this.isDisplaySearchBar) {
      this.isInfosRead = true;
      this.currentSearchTerm = "";
      this.paginationParams.pageNumber = 1;
      this.loadInfosByPagination(true);
    }
  }

  onSearch(searchTerm: string): void {
    if (searchTerm) {
      this.currentSearchTerm = searchTerm;
      this.paginationSearchParams.pageNumber = 1;
      this.loadInfosSearchByPagination(searchTerm, true);
    } else {
      this.loadInfosByPagination(true);
    }
  }

  loadInfosSearchByPagination(searchTerm: string, isRefresh: boolean = false) {
    if (this.isInfosRead) {
      this._resetBodyParams();
    } else {
      this._resetBodySearchParams();
      this.paginationSearchBody.push(this.paginationBodyUnread[0], this.paginationBodyUnread[1]);
    }
    this._store.dispatch(TeamInfoActions.postItemsSearchedPaginated({
        searchTerm,
        requestParam: {
          params: this.paginationSearchParams, body: this.paginationSearchBody, isRefresh: isRefresh
        }
      })
    );
  }

  /**
   * @name _resetBodyParams
   * @description
   * reset the body params to a default pagination body
   * @memberof TeamInfoPage
   * @private
   */
  private _resetBodyParams() {
    this.paginationBody = [this.defaultPaginationBody[0]];
  }

  private _resetBodySearchParams() {
    this.paginationSearchBody = [this.defaultPaginationBody[0]];
  }
}
