import {Injectable} from '@angular/core';
import {SharedModalControllerService} from '@shared/services/shared-modal-controller.service';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {TeamLoginState} from '@team/pages/login/store/team-login.state';
import {TeamLoginNewPasswordDialogComponent} from '@team/pages/login/components/new-password-dialog/team-login-new-password-dialog.component';
import {TeamLoginActions} from '@team/pages/login/store/team-login.actions-type';
import {Router} from '@angular/router';
import {AlertBaseControllerService} from '@shared/services/shared-alert-controller.service';
import {MeDto} from '@server-models';
import {lastValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SharedLoginStorageBaseService} from '@shared/stores/login-base/services/shared-login-storage-base.service';
import {TeamLoginRequestRegisterUserDialogComponent} from '@team/pages/login/components/request-register/components/user-dialog/team-login-request-register-user-dialog.component';
import {TeamLoginPinRegisterUserDialogComponent} from '@team/pages/login/components/pin-register-user-dialog/team-login-pin-register-user-dialog.component';
import {TeamLoginInvitationCodeDialogComponent} from '@team/pages/login/components/invitation-code/components/dialog/team-login-invitation-code-dialog.component';
import {SharedTenantSelectionModalComponent} from '@shared/components/tenant-selection-modal/shared-tenant-selection-modal.component';

@Injectable({
  providedIn: 'root',
})
export class TeamLoginSideEffectsService {
  constructor(
    private _store: Store<TeamLoginState>,
    private _loginBaseStorageService: SharedLoginStorageBaseService,
    private _alertControllerService: AlertBaseControllerService,
    private _modalControllerService: SharedModalControllerService,
    private _translateService: TranslateService,
    private _router: Router
  ) {
  }

  /**
   * @name hasMultipleTenants
   * @description
   * checks if there are multiple tenants
   * @memberof TeamLoginSideEffectsService
   * @param tokenInfo
   * @returns {boolean}
   */
  hasMultipleTenants(tokenInfo: MeDto): boolean {
    const tenants = tokenInfo?.tenants;
    return tenants?.length! > 1;
  }

  /**
   * @name getTenants
   * @description
   * display modal if not tenantId existing else dispatch already existing tenant
   * @memberof TeamLoginSideEffectsService
   * @param tokenInfo
   */
  async getTenants(tokenInfo: MeDto): Promise<void> {
    const tenantId = await lastValueFrom(this._checkExistingTenants());
    if (!tenantId) {
      this._modalControllerService.showModal(
        SharedTenantSelectionModalComponent,
        '',
        {
          data: tokenInfo.tenants
        }
      );
    } else {
      this._store.dispatch(
        TeamLoginActions.existingMultiTenant({ tenantId: tenantId! })
      );
    }
  }

  /**
   * @name _checkExistingTenants
   * @description
   * returns tenantId if exists
   * @memberof TeamLoginSideEffectsService
   * @private
   * @returns {Observable<number | undefined>}
   */
  private _checkExistingTenants(): Observable<number | undefined> {
    return this._loginBaseStorageService
      .getSt()
      .pipe(map((value) => value?.currentTenant?.tenantId));
  }

  askRegistrationData(currentEmail: string) {
    this._modalControllerService.showModal(
      TeamLoginRequestRegisterUserDialogComponent,
      '',
      {
        currentEmail,
      }
    );
  }

  askRegistrationPinGuest(passwordBase64: string, email: string) {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(TeamLoginPinRegisterUserDialogComponent, '', {
      passwordBase64,
      email,
    });
  }

  askForTeamCode() {
    this._modalControllerService.showModal(
      TeamLoginInvitationCodeDialogComponent,
      '',
      {}
    );
  }

  askForInvitationCode(code: string) {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(TeamLoginInvitationCodeDialogComponent, '', {
      code,
    });
  }

  invitationCodeRevoked() {
    this._alertControllerService.observableAlert({
      header: this._translateService.instant(
        'TEAM.WELCOME.DIALOGS.INVITATION_GUEST.ALERT.REVOKED.HEADER'
      ),
      subHeader: this._translateService.instant(
        'TEAM.WELCOME.DIALOGS.INVITATION_GUEST.ALERT.REVOKED.SUBHEADER'
      ),
      message: this._translateService.instant(
        'TEAM.WELCOME.DIALOGS.INVITATION_GUEST.ALERT.REVOKED.MESSAGE'
      ),
      buttons: [
        {
          text: this._translateService.instant(
            'TEAM.WELCOME.DIALOGS.INVITATION_GUEST.ALERT.REVOKED.BUTTONS.OK'
          ),
        },
      ],
    });
  }

  /**
   * @name newPasswordDialog
   * @description
   * display a modal of new password
   * @memberof TeamLoginSideEffectsService
   * @param token
   * @param email
   */
  newPasswordDialog(token: string, email: string): void {
    this._modalControllerService.closeModal();
    this._modalControllerService.showModal(TeamLoginNewPasswordDialogComponent, '', {
      newPasswordData: {
        token,
        email,
      },
    });
  }

  /**
   * @name launchAppByPassword
   * @description
   * dispatch an action login by password
   * @memberof TeamLoginSideEffectsService
   * @param email
   * @param password
   */
  launchAppByPassword(email: string, password: string): void {
    this._store.dispatch(
      TeamLoginActions.byPassword({ login: email, password: password })
    );
  }

  /**
   * @name resetRequestPasswordAlert
   * @description
   * display an alert to info the user that the email have sent successfully
   * @memberof TeamLoginSideEffectsService
   */
  resetRequestPasswordAlert(): void {
    this._alertControllerService.observableAlert({
      header: this._translateService.instant(
        'LOGIN.RESET_PASSWORD.ALERT.HEADER'
      ),
      subHeader: this._translateService.instant(
        'LOGIN.RESET_PASSWORD.ALERT.SUB_HEADER'
      ),
      message: this._translateService.instant(
        'LOGIN.RESET_PASSWORD.ALERT.MESSAGE'
      ),
      buttons: [
        {
          text: this._translateService.instant(
            'LOGIN.RESET_PASSWORD.ALERT.BUTTONS.ACCEPT'
          ),
          handler: () => {
            this._router.navigateByUrl('team/login');
          },
        },
      ],
    });
  }
}
