import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SharedModalControllerService} from "@shared/services/shared-modal-controller.service";
import {IonicModule} from "@ionic/angular";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {SharedSafePipe} from "@shared/pipes/shared-safe.pipe";
import {SharedCustomPropertyFormInputTextComponent} from "@shared/components/custom-property-form/components/input/components/text/shared-custom-property-form-input-text.component";

@Component({
  selector: 'app-team-login-invitation-code-form',
  templateUrl: './team-login-invitation-code-form.component.html',
  styleUrls: ['./team-login-invitation-code-form.component.scss'],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SharedSafePipe,
    SharedCustomPropertyFormInputTextComponent,
  ],
  standalone: true
})
export class TeamLoginInvitationCodeFormComponent implements OnInit {

  invitationCodeForm: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;
  @Output() submitChange: EventEmitter<FormGroup>;

  constructor(
    private _modalControllerService: SharedModalControllerService,
    public formBuilder: FormBuilder,
  ) {
    this.invitationCodeForm = new FormGroup({});
    this.formChange = new EventEmitter<FormGroup>();
    this.submitChange = new EventEmitter<FormGroup>();
  }

  getControl(controlName: string) {
    return this.invitationCodeForm.get(controlName) as FormControl;
  }

  ngOnInit() {
    this.initializeInvitationCodeForm();
    this.invitationCodeForm.valueChanges.subscribe(() => {
      this.formChange.emit(this.invitationCodeForm);
    });
  }

  /**
   * @name initializeInvitationCodeForm
   * @description
   * init invitation code form
   * @memberof TeamLoginInvitationCodeFormComponent
   */
  initializeInvitationCodeForm() {
    this.invitationCodeForm = this.formBuilder.group({
      lastName: ['', Validators.required],
      pin: ['', [Validators.required, Validators.maxLength(5), Validators.pattern('^[0-9]*$')]],
    });
  }

  /**
   * @name exit
   * @description
   * close the modal
   * @memberof TeamLoginInvitationCodeFormComponent
   */
  exit(): void {
    this._modalControllerService.closeModal();
  }

  /**
   * @name submitForm
   * @description
   * emit the changes
   * @memberof TeamLoginInvitationCodeFormComponent
   */
  submitForm() {
    this.submitChange.emit(this.invitationCodeForm);
  }

  /**
   * @name formatPin
   * @description
   * format the pin to a full number
   * @memberof TeamLoginInvitationCodeFormComponent
   * @param pinArray
   * @returns {number}
   */
  formatPin(pinArray: number[]): number {
    return parseInt(pinArray.join(''));
  }

  /**
   * @name isRequired
   * @description
   * check if a control is required
   * @memberof TeamLoginInvitationCodeFormComponent
   * @param controlName
   */
  isRequired(controlName: string): boolean {
    const control = this.invitationCodeForm.get(controlName);
    if (control) {
      const validator = control.validator ? control.validator({} as any) : null;
      return !!(validator && validator['required']);
    }
    return false;
  }
}
