import {Injectable} from "@angular/core";
import {IOrgaResponse} from "@shared/interfaces/orga-response.interface";
import {IPaginationParams} from "@shared/interfaces/pagination-params.interface";
import {
  FilterDto,
  IssueListDto,
  IssueTemplateListDto,
  Orders,
  ResourceListDto
} from "@server-models";
import {Observable} from "rxjs";
import {IRequestOptions} from "@shared/interfaces/request-options.interface";
import {IIssueService} from "@shared/components/issues/interfaces/issue-service.interface";
import {TTemplatePreviewDto} from "@shared/components/issues/types/template-preview-dto.type";
import {TIssueDto} from "@shared/components/issues/types/issue-dto.type";
import {TSubmitIssueDto} from "@shared/components/issues/types/submit-issue-dto.type";
import {CoreHttpClientApiBaseService} from "@core/services/http-client-api-base/core-http-client-api-base.service";

@Injectable({
  providedIn: 'root',
})
export abstract class TeamIssueApiService extends CoreHttpClientApiBaseService implements IIssueService {

  abstract getIssueById(issueId: number): Observable<TIssueDto>;

  abstract getIssueTemplatePreviewListById(id: number): Observable<TTemplatePreviewDto>;

  abstract sendIssueTemplatePreviewById(templateId: number, issue: TSubmitIssueDto): Observable<void>;

  getIssueRecentList(params: IPaginationParams): Observable<IOrgaResponse<IssueListDto[]>> {
    const options = {
      params: {
        PageSize: params.pageSize.toString(),
        PageNumber: params.pageNumber.toString(),
        Cols: params.cols,
        SortField: "lastModified",
        Sort: Orders.Descending.toString()
      }
    }
    return super.get<IOrgaResponse<IssueListDto[]>>(`/tech/issue/all`, undefined, options);
  }

  postIssueRecentFilterList(body: FilterDto[], options: IRequestOptions): Observable<IOrgaResponse<IssueListDto[]>> {
    return super.post<IOrgaResponse<IssueListDto[]>>(`/tech/issue/all/filter`, undefined, body, options)
  }

  getIssueTemplateList(): Observable<IOrgaResponse<IssueTemplateListDto[]>> {
    return super.get<IOrgaResponse<IssueTemplateListDto[]>>(`/tech/issue/template`, undefined)
  }

  postIssueTemplateFilterList(body: FilterDto[]): Observable<IOrgaResponse<IssueTemplateListDto[]>> {
    return super.post<IOrgaResponse<IssueTemplateListDto[]>>(`/tech/issue/template/filter`, undefined, body)
  }

  requestResourceListByFilter(filter: FilterDto[]): Observable<IOrgaResponse<ResourceListDto>> {
    return super.post<IOrgaResponse<ResourceListDto>>(`/tech/resource/all/filter`, undefined, filter)
  }
}
