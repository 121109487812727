import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {filter, first, Observable} from "rxjs"
import {tap} from "rxjs/operators";
import {TeamChatEntityService} from "./team-chat-entity.service";

@Injectable({
  providedIn: 'root',
})
export class TeamChatResolver implements Resolve<boolean> {

  constructor(private chatEntityService: TeamChatEntityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.chatEntityService.loaded$
      .pipe(
        tap(loaded => {
          if (!loaded) {
            this.chatEntityService.getAll();

            // if (loginStartDemoSuccess()) {
            //   this.demoEntityService.getByKey('chat');
            // }
          }
        }),
        filter(loaded => !!loaded),
        first()
      );
  }
}
