<app-side-bar></app-side-bar>
<div class="ion-page" id="main-content">
  <ion-tabs>
    <ion-tab-bar slot="bottom">
      <ion-tab-button tab="infos">
        <ion-icon name="information-circle"></ion-icon>
        <ion-label [innerText]="'TEAM.TAB_NAVIGATION.INFOS' | translate"></ion-label>
      </ion-tab-button>

      <ion-tab-button tab="knowledge">
        <ion-icon name="help"></ion-icon>
        <ion-label [innerText]="'TEAM.TAB_NAVIGATION.KNOWLEDGE' | translate"></ion-label>
      </ion-tab-button>

      <ion-tab-button tab="documents">
        <ion-icon name="document"></ion-icon>
        <ion-label [innerText]="'TEAM.TAB_NAVIGATION.DOCUMENTS' | translate"></ion-label>
      </ion-tab-button>

      <ion-tab-button tab="issues">
        <ion-icon name="file-tray-full"></ion-icon>
        <ion-label [innerText]="'TEAM.TAB_NAVIGATION.ISSUES' | translate"></ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</div>
